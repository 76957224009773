import { createSlice } from '@reduxjs/toolkit';
import { AiModel, StringKeys } from 'src/types/base';

export type AssistantType = {
  name?: string;
  description?: string;
  instructions?: string;
  conversationStarter?: string[];
  aiModel?: AiModel;
  fileList?: StringKeys[];
  isWebSearch?: boolean;
  assistantIcon?: string;
  permission?: StringKeys;
  worldKnowledge?: boolean;
  id?: number;
  settings?: StringKeys;
};

type AssistantContextType = {
  isPreview: boolean;
  isAssistant: boolean;
  isStartChat: boolean;
  isCreatingAssistant: boolean;
  isUpdated: boolean;
};
type InitiateAssistant = {
  assistantObj: AssistantType;
  assistantContext: AssistantContextType;
  starterQuestion: string;
};

export const initialAssistantState = {
  name: '',
  description: '',
  instructions: '',
  conversationStarter: [],
  aiModel: null,
  fileList: [],
  isWebSearch: false,
  assistantIcon: '',
  permission: {},
  worldKnowledge: false,
  id: null,
  settings: {}
};

export const initialAssistantContextState = {
  isPreview: false,
  isAssistant: false,
  isStartChat: false,
  isCreatingAssistant: false,
  chatId: 0,
  isUpdated: false
};

const initialState: InitiateAssistant = {
  assistantObj: initialAssistantState,
  assistantContext: initialAssistantContextState,
  starterQuestion: ''
};

const slice = createSlice({
  name: 'assistant',
  initialState,
  reducers: {
    updateInitiateAssistantState: (
      state,
      action: { payload: AssistantType }
    ) => {
      const { payload } = action;
      Object.assign(state.assistantObj, payload);
    },
    updateAssistantContext: (
      state,
      action: { payload: AssistantContextType }
    ) => {
      const { payload } = action;
      Object.assign(state.assistantContext, payload);
    },
    updateStarterQuestion: (state, action: { payload: string }) => {
      const { payload } = action;
      state.starterQuestion = payload;
    }
  }
});

export const reducer = slice.reducer;
export const {
  updateInitiateAssistantState,
  updateAssistantContext,
  updateStarterQuestion
} = slice.actions;

export default slice;
