import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabContext, TabPanel } from '@mui/lab';
import {
  useLazyGetAssistantContentAccessRightsQuery,
  useLazyGetContentAccessRightsQuery,
  useLazyGetRolesQuery
} from 'src/services/api';
import Loader from 'src/components/Loader';
import InviteUsersForm from './InviteUsersForm';
import { useForm } from 'react-hook-form';
import SharedWithUsers from './SharedWithUsers';
import { debounce } from 'lodash';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { StringKeys } from 'src/types/base';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { DataApiInputParams } from 'src/types/api';
import { makeStyles } from '@mui/styles';
import { ErrorContext } from 'src/utils/errorMappings';
import useUpdateContentAccessRights from 'src/hooks/useUpdateAccessRights';
import { sharingRoleDescriptions } from '../utils/utils';
import { useDispatch, useSelector } from 'src/redux/store';
import { VerticalContainer } from './styles';
import { RESOURCE_TYPE } from 'src/types/enum';
import { updateAssistantContext } from 'src/redux/slices/assistant';

const useStyles = makeStyles(() => ({
  tabPanel: {
    padding: '0 !important',
    minHeight: '241px',
    height: '241px',
    maxHeight: '241px'
  }
}));

type IProps = {
  isOpen: boolean;
  onClose: () => void;
  resource?: any;
  isExternalLinkedResource?: boolean;
  resourceType?: string;
};

type FormValues = {
  users?: any[];
  role: any;
  // message?: string;
  // sendEmail: boolean;
  advanced: string;
};

const ContentSharing: React.FC<IProps> = ({
  isOpen,
  onClose,
  resource,
  isExternalLinkedResource = false,
  resourceType = RESOURCE_TYPE.FILE
}: IProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { applicationResources } = useSelector((state) => state.data);
  const { user } = useSelector((state) => state.auth);
  const { assistantContext } = useSelector((state) => state.assistant);
  const [tabIndex, setTabIndex] = useState('invite');
  const [
    fetchResourceRoles,
    { data: contentRoles, isLoading: isRolesLoading }
  ] = useLazyQuery<DataApiInputParams, StringKeys>({
    api: useLazyGetRolesQuery,
    errorContext: ErrorContext.ROLES
  });
  const [
    fetchContentAccessDetail,
    { data: contentAccessRights, isLoading: isLoadingPermissions, isFetching }
  ] = useLazyQuery<DataApiInputParams, any[]>({
    api: useLazyGetContentAccessRightsQuery
  });
  const [
    fetchAssistantContentAccessDetail,
    {
      data: assistantContentAccessRights,
      isLoading: isLoadingAssistantPermissions,
      isFetching: isAssistantPermissionFetching
    }
  ] = useLazyQuery<DataApiInputParams, any[]>({
    api: useLazyGetAssistantContentAccessRightsQuery
  });
  const { handleUpdateAccessRights, isUpdatingAccess } =
    useUpdateContentAccessRights();

  const formMethods = useForm<FormValues>();
  const { handleSubmit, watch } = formMethods;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [removedAccessRights, setRemovedAccessRight] = useState<any[]>([]);
  const watchUsers = watch('users', []);
  const classes = useStyles();
  const { id: resourceId, name: resourceName } = resource || {
    id: null,
    name: null
  };

  const contentRolesWithSecondaryText = useMemo(() => {
    if (contentRoles) {
      return contentRoles.map((item) => ({
        ...item,
        secondaryText: t(sharingRoleDescriptions[item.name] || item.description)
      }));
    }
    return [];
  }, [contentRoles, i18n.language]);

  const isAssistant: boolean = useMemo(
    () => resourceType === RESOURCE_TYPE.ASSISTANT,
    [resourceType]
  );

  useEffect(() => {
    if (isAssistant) {
      fetchResourceRoles({
        params: {
          params: {
            sorting: true,
            show_details: true,
            restricted_roles: 'Contributor'
          }
        }
      });
      fetchAssistantContentAccessDetail({
        params: { params: { id: resourceId } }
      });
    } else {
      fetchResourceRoles({
        params: { params: { sorting: true, show_details: true } }
      });
      fetchContentAccessDetail({ params: { params: { id: resourceId } } });
    }
  }, []);

  const handleSearch = useCallback(
    debounce((s: string) => {
      if (isAssistant) {
        fetchAssistantContentAccessDetail({
          params: { params: { s, id: resourceId } }
        });
      } else {
        fetchContentAccessDetail({ params: { params: { s, id: resourceId } } });
      }
    }, 250),
    [isAssistant]
  );

  const handleUserSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchQuery(() => e.target.value);
      handleSearch(e.target.value);
    },
    []
  );

  const handleRemoveAccessRight = (accessRight: any) => {
    setRemovedAccessRight((prev) => [...prev, accessRight.p_id]);
  };

  const onSubmit = handleSubmit((data: any) => {
    let removedUsers = [];
    if (isAssistant) {
      removedUsers = assistantContentAccessRights?.filter((accessRight) => {
        if (removedAccessRights.includes(accessRight.p_id)) {
          return accessRight;
        }
      });
      if (assistantContext?.isCreatingAssistant) {
        dispatch(
          updateAssistantContext({
            ...assistantContext,
            isCreatingAssistant: false
          })
        );
      }
    } else {
      removedUsers = contentAccessRights?.filter((accessRight) =>
        removedAccessRights.includes(accessRight.p_id)
      );
    }
    handleUpdateAccessRights({
      id: resourceId,
      role: data.role,
      addedUsers: data.users,
      removedUsers: removedUsers,
      assignmentType: data.advanced,
      type_id: applicationResources.User,
      isAssistant: isAssistant
    });

    onClose();
  });

  const handleTabChange = (_, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleKeepPrivate = () => {
    if (assistantContentAccessRights) {
      // this is the logic to remove the existing shared with users/groups

      // handleUpdateAccessRights({
      //   id: resourceId,
      //   role: formMethods.getValues('role'),
      //   addedUsers: formMethods.getValues('users'),
      //   removedUsers: assistantContentAccessRights.filter(
      //     (usr) => usr.value !== user?.username
      //   ),
      //   assignmentType: formMethods.getValues('advanced'),
      //   type_id: applicationResources.User
      // });

      dispatch(
        updateAssistantContext({
          ...assistantContext,
          isCreatingAssistant: false
        })
      );
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: (theme) => ({
          borderRadius: theme.general.borderRadiusXl
        })
      }}
      onClose={onClose}
      keepMounted
    >
      {isUpdatingAccess && <Loader />}
      <form onSubmit={onSubmit}>
        <TabContext value={`${tabIndex}`}>
          <DialogTitle
            sx={{
              paddingBottom: '12px'
            }}
          >
            <Typography
              variant={'h3'}
              title={`${t(T.share)}${resourceName ? ` - ${resourceName}` : ''}`}
              sx={{
                fontWeight: 'normal',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}
            >
              {t(T.share)}
              {resourceName ? ` - ${resourceName}` : ''}
            </Typography>
            <Tabs
              sx={{ marginTop: '18px' }}
              value={tabIndex}
              onChange={handleTabChange}
            >
              <Tab
                value={'invite'}
                label={
                  <Typography variant="body2" color="primary">
                    {t(T.invite)}
                  </Typography>
                }
              />
              <Tab
                value={'shared'}
                label={
                  <Typography variant="body2">{t(T.sharedWith)}</Typography>
                }
              />
            </Tabs>
          </DialogTitle>
          <DialogContent sx={{ overflow: 'hidden' }}>
            {isRolesLoading ||
            isLoadingPermissions ||
            isLoadingAssistantPermissions ? (
              <Loader />
            ) : (
              <>
                <TabPanel
                  className={classes.tabPanel}
                  sx={{
                    overflow: 'auto'
                  }}
                  value={'invite'}
                >
                  {isExternalLinkedResource ? (
                    <VerticalContainer>
                      <Typography>{t(T.externalResourceDisclaimer)}</Typography>
                    </VerticalContainer>
                  ) : (
                    <InviteUsersForm
                      contentRoles={contentRolesWithSecondaryText}
                      formMethods={formMethods}
                      isAssistant={isAssistant}
                    />
                  )}
                </TabPanel>
                <TabPanel className={classes.tabPanel} value={'shared'}>
                  <Box
                    sx={(theme) => ({
                      border: `1px solid ${theme.colors.primaryAlt.main}`,
                      padding: theme.spacing(1)
                    })}
                  >
                    <TextField
                      name="userSearch"
                      label=""
                      onChange={handleUserSearchChange}
                      fullWidth
                      placeholder={t(T.search)}
                      value={searchQuery}
                      sx={{
                        input: {
                          padding: '10px 14px'
                        }
                      }}
                    />
                  </Box>
                  <SharedWithUsers
                    isFetchingAccessRights={
                      isFetching || isAssistantPermissionFetching
                    }
                    sharedWithUsers={
                      isAssistant
                        ? assistantContentAccessRights
                        : contentAccessRights
                    }
                    onRemoveAccessRight={handleRemoveAccessRight}
                    removedAccessRight={removedAccessRights}
                    searchQuery={searchQuery}
                    hideRemoveAccessRights={isExternalLinkedResource}
                  />
                </TabPanel>
              </>
            )}
          </DialogContent>
          <DialogActions>
            {isAssistant && assistantContext?.isCreatingAssistant ? (
              <Button
                onClick={handleKeepPrivate}
                variant="outlined"
                size="small"
              >
                {t(T.keepItPrivate)}
              </Button>
            ) : (
              <Button onClick={onClose} variant="outlined" size="small">
                {t(T.cancel)}
              </Button>
            )}
            {!isExternalLinkedResource && (
              <Button
                onClick={onSubmit}
                variant="contained"
                type="submit"
                disabled={
                  (watchUsers?.length === 0 &&
                    removedAccessRights.length === 0) ||
                  isUpdatingAccess
                }
                size="small"
                autoFocus
                color="secondary"
              >
                {t(T.confirm)}
              </Button>
            )}
          </DialogActions>
        </TabContext>
      </form>
    </Dialog>
  );
};

export default ContentSharing;
