import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { Box, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { CustomAddIcon, CustomDeleteIcon } from '../utils/styles';

type ConversationStarterProps = {
  id: number;
  name: string;
  handleStarterChange?: (id: number, starterValue: string) => void;
  handleAddStarter?: () => void;
  removeStarter?: (starterId: number) => void;
  isFocused?: boolean;
  showAddButton?: boolean;
  showDeleteButton?: boolean;
};

const ConversationStarter = ({
  id,
  name,
  handleStarterChange,
  handleAddStarter,
  removeStarter,
  isFocused = false,
  showAddButton = true,
  showDeleteButton = true
}: ConversationStarterProps) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    handleStarterChange(id, e.target.value);
  };

  const handleDelete = () => {
    removeStarter(id);
  };

  const handleStarterKeydown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && handleAddStarter) {
      handleAddStarter();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
      mb={2}
    >
      <TextField
        focused={isFocused}
        variant="outlined"
        size="medium"
        sx={{
          width: '90%'
        }}
        value={name}
        placeholder={t(T.conversationStartersPlaceholder)}
        onChange={handleChange}
        onKeyDown={handleStarterKeydown}
      />

      {showDeleteButton && (
        <CustomDeleteIcon fontSize="large" onClick={handleDelete} />
      )}
      {showAddButton && (
        <CustomAddIcon fontSize="large" onClick={handleAddStarter} />
      )}
    </Box>
  );
};

export default ConversationStarter;
