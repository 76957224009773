import { Box, Grid, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_VERSION } from 'src/config';
import { useSelector, RootState } from 'src/redux/store';
import { CHAT_SOURCE_TYPES } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

const AppVersion = ({ decreaseMaxWidth }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    selectedChat: chatId,
    chatContexts,
    chats
  } = useSelector((state: RootState) => state.chat);

  const isEmpowerGPTChat = useMemo(
    () =>
      chats?.find((c) => c?.id === chatId)?.chat_type ===
        CHAT_SOURCE_TYPES.EmpowerGPT ||
      chatContexts[0]?.chat_type === CHAT_SOURCE_TYPES.EmpowerGPT,
    [chatId, chats, chatContexts]
  );

  return (
    <Box
      sx={{
        marginX: 'auto',
        paddingY: '10px',
        [theme.breakpoints.up('xl')]: {
          maxWidth: decreaseMaxWidth
            ? `${theme.breakpoints.values.sm}px`
            : `${theme.breakpoints.values.md}px`
        },
        [theme.breakpoints.down('xl')]: {
          maxWidth: decreaseMaxWidth
            ? `calc(${theme.breakpoints.values.sm}px - 180px)`
            : `calc(${theme.breakpoints.values.md}px - 180px)`
        }
      }}
    >
      <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        width={'100%'}
      >
        <Grid item>
          <span
            style={{
              fontWeight: 300,
              color: '#323639',
              fontSize: '12px'
            }}
          >
            {`${
              isEmpowerGPTChat ? t(T.disclaimerEgpt) : t(T.disclaimerChatgpt)
            } ${T.empowerGPT} ${APP_VERSION || 'developer release'}`}
          </span>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppVersion;
