import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { ReactComponent as Logo } from 'src/assets/empowergpt-logo.svg';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import QuestionCard from './QuestionCard';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';

const ChatGpt = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Stack
      id="test"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        gap: '30px'
      }}
    >
      <Box paddingX={theme.spacing(2.5)}>
        <Logo width={230} height={80} />
      </Box>
      <Box>
        <Typography fontSize={'17px'} lineHeight={'24px'}>
          {t(T.chatGptSlogan)}
        </Typography>
      </Box>
      <Box width={'100%'}>
        <Box
          sx={{ marginTop: 2 }}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <LightModeOutlinedIcon
            fontSize="large"
            htmlColor={theme.colors.secondary.dark}
          />
          <Box>
            <Typography marginTop={1} variant="h4">
              {t(T.samplePrompts)}
            </Typography>
          </Box>
        </Box>
        <Grid
          container
          maxWidth={'md'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          width={'80%'}
          marginX={'auto'}
        >
          <Grid
            item
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            xs={12}
            md={4}
            paddingX={theme.spacing(1)}
            paddingTop={theme.spacing(1.5)}
            gap={1}
          >
            <Box>
              <QuestionCard message={t(T.samplePrompt4)} />
            </Box>
            <Box>
              <QuestionCard message={t(T.samplePrompt5)} />
            </Box>
          </Grid>
          <Grid
            item
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            xs={12}
            md={4}
            paddingX={theme.spacing(1)}
            paddingTop={theme.spacing(1.5)}
            gap={1}
          >
            <Box>
              <QuestionCard message={t(T.samplePrompt7)} />
            </Box>
            <Box>
              <QuestionCard message={t(T.samplePrompt8)} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default ChatGpt;
