import {
  useTheme,
  Autocomplete,
  TextField,
  Box,
  Popper,
  autocompleteClasses,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RootState, useSelector } from 'src/redux/store';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { SelectItem } from 'src/types/base';
import { TagType } from '../utils/utils';
import { styled } from '@mui/styles';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    maxHeight: 150,
    width: '100%',
    overflowY: 'auto'
  }
});

type MultiSelectProps = {
  tagsList: SelectItem[];
  defaultValue?: SelectItem[];
  onTagChange: (selected: TagType) => void;
  limitTags?: number;
  freeSolo?: boolean;
  size?: 'small' | 'medium' | 'large';
  helperText?: string;
  autoFocus?: boolean;
  maxLength?: number;
};

const MultiSelectTags = ({
  defaultValue,
  onTagChange,
  freeSolo,
  tagsList,
  helperText,
  maxLength,
  ...props
}: MultiSelectProps) => {
  const { t }: { t: any } = useTranslation();
  const [charCount, setCharCount] = useState<number>(0);
  const theme = useTheme();

  const onChange = (event: any, newValue: TagType) => {
    onTagChange(newValue);
    setCharCount(0);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.toString().slice(0, maxLength);
    const value = event.target.value;
    setCharCount(value.length);
  };
  return (
    <Box sx={{ height: 130 }}>
      <Autocomplete
        multiple
        id="multiple tags"
        size="medium"
        options={tagsList}
        autoHighlight
        freeSolo={freeSolo !== undefined ? freeSolo : true}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option?.name
        }
        defaultValue={defaultValue}
        limitTags={props.limitTags}
        onChange={onChange}
        PopperComponent={StyledPopper}
        popupIcon={<KeyboardArrowDownIcon fontSize="medium" />}
        sx={{
          fontSize: theme.typography.pxToRem(14),
          position: 'relative',
          mr: 0,
          p: 0
        }}
        renderInput={(params) => (
          <Box>
            <TextField
              {...params}
              label={t(T.selectTags)}
              placeholder={t(T.enterTagName)}
              helperText={helperText || ''}
              autoFocus={props?.autoFocus}
              {...(maxLength && { onInput: handleInputChange })}
            />
            {maxLength && (
              <Typography
                variant="caption"
                sx={{ position: 'absolute', bottom: 0, right: 0 }}
              >
                {charCount}/{maxLength}
              </Typography>
            )}
          </Box>
        )}
      />
    </Box>
  );
};

export default MultiSelectTags;
