import { Box, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { updateInitiateAssistantState } from 'src/redux/slices/assistant';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { MAX_CONVERSATION_STARTERS } from '../utils/utils';
import ConversationStarter from './ConversationStarter';
import { IConversationStarter } from '../utils/types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'src/redux/store';

const ConversationStarters = ({
  conversationStarters,
  setConversationStarters,
  newAssistant,
  setStarterError
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dummyStarter, setDummyStarter] = useState<IConversationStarter>({
    id: 1,
    name: ''
  });

  const handleAddStarter = useCallback(() => {
    if (
      conversationStarters.length < MAX_CONVERSATION_STARTERS &&
      dummyStarter.name.length > 0
    ) {
      let starters = [...conversationStarters];
      let tempDummyStarter = {
        ...dummyStarter,
        id: starters.length + 1
      };
      starters.push(tempDummyStarter);
      setConversationStarters(starters);
      setDummyStarter({ id: starters.length, name: '' });
      dispatch(
        updateInitiateAssistantState({
          ...newAssistant,
          conversationStarter: starters
        })
      );
    }
  }, [conversationStarters, dummyStarter]);

  const handleRemoveStarter = useCallback(
    (starterId: number) => {
      let starters = [...conversationStarters];
      let tempStarterError: boolean = false;
      starters = starters.filter((c) => c.id !== starterId);
      starters = starters.map((starter) => {
        if (starter.name.length === 0) {
          tempStarterError = true;
        }
        return starter;
      });
      if (tempStarterError) {
        setStarterError(true);
      } else {
        setStarterError(false);
      }
      setConversationStarters(starters);

      dispatch(
        updateInitiateAssistantState({
          ...newAssistant,
          conversationStarter: starters
        })
      );
    },
    [conversationStarters]
  );

  const handleOnChange = useCallback(
    (id, starterValue) => {
      let tempStarterError: boolean = false;
      const tempStarter = [...conversationStarters];
      let myTempStarter = tempStarter.map((starter) => {
        if (starter.id === id) {
          starter = { ...starter, name: starterValue };
        }
        if (starter.name.length === 0) {
          tempStarterError = true;
        }
        return starter;
      });
      if (tempStarterError) {
        setStarterError(true);
      } else {
        setStarterError(false);
      }
      setConversationStarters(myTempStarter);
      dispatch(
        updateInitiateAssistantState({
          ...newAssistant,
          conversationStarter: myTempStarter
        })
      );
    },
    [newAssistant, conversationStarters]
  );

  const handleDummyStarterOnChange = useCallback((id, starterValue) => {
    setDummyStarter({ id, name: starterValue });
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mb="10px">
        <Typography variant="body1">{t(T.conversationStarters)}</Typography>
        <Typography variant="body1">
          {t(T.conversationStartersCount, {
            totalCon: conversationStarters?.length
          })}
        </Typography>
      </Box>
      <Box>
        {conversationStarters?.map((item, index) => {
          return (
            <>
              <ConversationStarter
                id={item.id}
                name={item.name}
                handleStarterChange={handleOnChange}
                removeStarter={handleRemoveStarter}
                key={index}
                showAddButton={false}
              />
              {item.name.length === 0 && (
                <Typography
                  variant="body1"
                  color="red"
                  mt={'-10px'}
                  mb={'10px'}
                >
                  {t(T.conversationStartersErrorMessage)}
                </Typography>
              )}
            </>
          );
        })}
        {conversationStarters.length != MAX_CONVERSATION_STARTERS && (
          <ConversationStarter
            id={dummyStarter.id}
            name={dummyStarter.name}
            handleStarterChange={handleDummyStarterOnChange}
            handleAddStarter={handleAddStarter}
            isFocused={true}
            showDeleteButton={false}
          />
        )}
      </Box>
    </Box>
  );
};

export default ConversationStarters;
