import { t } from 'i18next';
import { StringKeys } from 'src/types/base';
import {
  FilterComponents,
  FilterElementTypes
} from 'src/types/custom_component';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

export const getFilters = (
  datasources: any,
  defaultValues?: StringKeys,
) => {
  let filters: FilterComponents[] = [
    {
      name: t(T.name),
      type: FilterElementTypes.Text,
      elementWidth: 300,
      dataField: 's',
      placeholder: T.search,
      showIcon: true,
      showDivider: true,
      defaultValue: defaultValues?.s
    },
    {
        name: t(T.sortBy),
        type: FilterElementTypes.DropDown,
        dataField: 'code',
        placeholder: T.sortBy,
        elementWidth: 250,
        datasource: datasources?.sortList,
        defaultValue: undefined,
        disableSearch: true
    }
  ];
   
  return filters;
};
