import { Close } from '@mui/icons-material';
import { styled, Box } from '@mui/material';

export const DialogBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    min-width: 650px;
    padding: 30px 0px 20px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    background: ${theme.palette.common.white};
  `
);

export const CloseButton = styled(Close)(
  `font-size: small;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 11px;
  top: 11px;
  cursor:pointer;`
);

export const ContentBox = styled(Box)(
  `display: flex;
  padding: 0px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;`
);

export const CopyLinkBox = styled(Box)(
  `display: flex;
  gap: 4px;
  cursor: pointer;
  align-items: center;
  color: #000C57;
  leading-trim: both;
  text-edge: cap;
  font-family: Switzer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  border-bottom: 1px solid;
  height: 29px;`
);

export const DescriptionBox = styled(Box)(
  `display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-self:stretch;
  align-items:center;`
);
