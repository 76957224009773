import keyBy from 'lodash/keyBy';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark as styleTheme } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { setAlert } from 'src/redux/slices/snackbar';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useCallback, useMemo, useState } from 'react';
import { CitationButton } from './styles';
import {
  addButtonsAfterDocument,
  removeAIMessage
} from 'src/content/Chat/utils';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { ReactComponent as Warning } from 'src/assets/Warning.svg';

const useStyles = makeStyles(() => ({
  codeBlockHeader: {
    backgroundColor: '#424242',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    color: '#FAFAFA',
    height: '36px',
    lineHeight: '36px',
    paddingLeft: '24px'
  }
}));

type MarkdownProps = {
  messageId?: number;
  value: string;
  files?: any;
  showCursor?: boolean;
  isDownloadingFile?: boolean;
  isSharedChat?: boolean;
  isErrorMessage?: boolean;
};

const Markdown = ({
  messageId,
  value,
  files = [],
  showCursor = false,
  isDownloadingFile,
  isSharedChat,
  isErrorMessage
}: MarkdownProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t }: { t: any } = useTranslation();
  const [selectedId, setSelectedId] = useState('');

  const openCitedDocument = (id: string) => {
    setSelectedId(id);
    const citedDocument = document?.getElementById(`${messageId || ''}+${id}`);
    if (citedDocument) {
      citedDocument?.click();
      return;
    }
    dispatch(
      setAlert({
        type: 'warning',
        msg: t(T.documentDownloadError)
      })
    );
  };

  const filesByID = useMemo(() => keyBy(files, 'document_id'), [files]);

  let buttonsArray = [];
  const renderers = {
    a: ({ children, ...rest }) => {
      const match = children[0]?.match(/document: |Document: |"/gi);
      const buttonId = children[0]?.replace(match, '');
      let indexOfButton = buttonsArray.indexOf(buttonId);
      if (indexOfButton === -1 && match) {
        buttonsArray.push(buttonId);
        indexOfButton = buttonsArray.length - 1;
      }
      const handleButtonClick = useCallback(() => {
        openCitedDocument(buttonId);
      }, [buttonId, openCitedDocument]);

      return match ? (
        <Tooltip title={filesByID[buttonId]?.name || ''}>
          <CitationButton
            variant="text"
            disabled={showCursor || isDownloadingFile || isSharedChat}
            loading={isDownloadingFile && selectedId === buttonId}
            props={{ isDownloadingFile, selectedId, buttonId }}
            onClick={handleButtonClick}
          >
            {indexOfButton + 1}
          </CitationButton>
        </Tooltip>
      ) : (
        <a {...rest}>{children}</a>
      );
    },
    hr: () => (
      <hr
        style={{
          border: 'none',
          borderTop: '1px solid rgba(0,0,0,.25)',
          width: '100%'
        }}
      />
    ),
    blockquote: ({ children }) => (
      <blockquote
        style={{
          border: 'none',
          borderLeft: '2px solid rgb(155,155,155)',
          margin: 0,
          padding: '8px 16px'
        }}
      >
        {children}
      </blockquote>
    ),
    code: (props) => {
      const { children, className, node, inline, ...rest } = props;
      const match = /language-(\w+)/.exec(className || '');
      const language = match?.[1];
      return language ? (
        <div>
          <div className={classes.codeBlockHeader}>{language}</div>
          <SyntaxHighlighter
            {...rest}
            PreTag="div"
            language={language}
            style={styleTheme}
            wrapLongLines={true}
            showLineNumbers={true}
            inline={`${inline}`}
            customStyle={{
              overflowY: 'hidden',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: '6px',
              borderBottomRightRadius: '6px',
              marginTop: 0,
              paddingTop: 0,
              paddingBottom: 0
            }}
          >
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
        </div>
      ) : !isErrorMessage ? (
        <code {...rest} className={className}>
          {children}
        </code>
      ) : (
        <p {...rest} className={className}>
          {children}
        </p>
      );
    }
  };

  const markdownWithButtons = useMemo(
    () => addButtonsAfterDocument(removeAIMessage(value)),
    [value]
  );

  const preprocessMathExpressions = useCallback((content: string) => {
    return content
      ?.replace(/\\\[(\s*[\s\S]*?)\\\]/g, '$$$1$$')
      ?.replace(/\\\((.*?)\\\)/g, '$$$1$$');
  }, []);

  const processedMarkdownContent = useMemo(
    () => preprocessMathExpressions(markdownWithButtons),
    [markdownWithButtons]
  );

  const ErrorMessage = useMemo(() => `\t      ${value}`, [isErrorMessage]);

  return (
    <>
      {!isErrorMessage ? (
        <div
          id={showCursor ? 'content-streaming' : undefined}
          style={{
            position: 'relative'
          }}
        >
          <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeKatex]}
            components={renderers}
          >
            {processedMarkdownContent}
          </ReactMarkdown>
        </div>
      ) : (
        <div
          id={showCursor ? 'content-streaming' : undefined}
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'row'
          }}
        >
          {isErrorMessage && (
            <Box
              sx={{
                position: 'absolute',
                top: '3px'
              }}
            >
              <Warning />
            </Box>
          )}
          <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeKatex]}
            components={renderers}
          >
            {ErrorMessage}
          </ReactMarkdown>
        </div>
      )}
    </>
  );
};
export default Markdown;
