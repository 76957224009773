import { useState, useEffect, useContext } from 'react';
import { Box, Avatar } from '@mui/material';
import { AnimatedText, SecondaryMessage } from '../styles';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { getInitials } from 'src/utils/utils';
import { useTranslation } from 'react-i18next';
import { AzureWebPubSubChatContext } from 'src/contexts/AzureWebPubSubChatContext';
import { ReactComponent as EInitials } from 'src/assets/egpt-logo.svg';

const ChatResponse = () => {
  const { t } = useTranslation();
  const [typingText, setTypingText] = useState(`...`);
  const { chatStep } = useContext(AzureWebPubSubChatContext);

  useEffect(() => {
    const typingInterval = setInterval(() => {
      setTypingText((prevText) => (prevText === '...' ? '' : '...'));
    }, 1000);

    return () => clearInterval(typingInterval);
  }, []);

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent={'flex-start'}
      py={1}
      width={'100%'}
    >
      <Avatar
        variant="rounded"
        sx={{
          width: 50,
          height: 50,
          borderRadius: 50,
          backgroundColor: (theme) => 'white'
        }}
        alt={t(T.empowerGPT)}
        style={{ objectFit: 'contain' }}
      >
        <EInitials />
      </Avatar>

      <Box
        display="flex"
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        flexDirection="column"
        mr={2}
        ml={2}
        flex={'1'}
      >
        <SecondaryMessage>
          <AnimatedText>
            {chatStep ? t(chatStep) : t(T.understandingInput)}
            {typingText}
          </AnimatedText>
        </SecondaryMessage>
      </Box>
    </Box>
  );
};

export default ChatResponse;
