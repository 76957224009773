import { Box, Dialog, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { ReactComponent as CopyLinkIcon } from 'src/assets/documentIcons/Copy link.svg';
import {
  CloseButton,
  ContentBox,
  CopyLinkBox,
  DescriptionBox,
  DialogBox
} from './styles';
import Disclaimer from 'src/content/Documents/Workspaces/WorkspaceCard/disclaimer';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from 'src/redux/slices/snackbar';
import { ALERT } from 'src/types/enum';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import { RootState } from 'src/redux/store';
import { BASE_SERVER_URL } from 'src/config';

interface ShareChatModalProps {
  isOpen: boolean;
  onClose: (value: any) => void;
}

const ShareChatModal: FC<ShareChatModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [animateCopyReaction, setCopyAnimation] = useState<boolean>(false);
  const { t } = useTranslation();
  const { selectedChat: chatId, chatContexts } = useSelector(
    (state: RootState) => state.chat
  );

  const shareChatLink = useMemo(
    () =>
      `${BASE_SERVER_URL}/share-chat/${chatContexts[chatId]?.shareChatData?.link}`,
    [chatContexts?.[chatId]?.shareChatData]
  );
  const onCopyReactionTimeout = () => setCopyAnimation(false);
  const copyLink = useCallback(() => {
    navigator.clipboard
      .writeText(shareChatLink)
      .then(() => {
        setCopyAnimation(true);
        setTimeout(onCopyReactionTimeout, 2000);
      })
      .catch(() => {
        dispatch(
          setAlert({
            msg: t(T.errorCopyingLink),
            type: ALERT.ERROR
          })
        );
      });
  }, [shareChatLink]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <DialogBox>
        <CloseButton onClick={onClose} />
        <ContentBox>
          <Typography variant="h3">{t(T.shareChatTitle)}</Typography>
          <DescriptionBox>
            <Box>
              <Typography variant="h6">{t(T.shareChatText)}</Typography>
              <Disclaimer disclaimerText={t(T.shareChatDisclaimer)} />
            </Box>
            <CopyLinkBox onClick={copyLink}>
              {!animateCopyReaction ? (
                <CopyLinkIcon />
              ) : (
                <DoneAllRoundedIcon fontSize="small" color="success" />
              )}
              <Typography variant="body1">
                {!animateCopyReaction ? t(T.copyLink) : t(T.copied)}
              </Typography>
            </CopyLinkBox>
          </DescriptionBox>
        </ContentBox>
      </DialogBox>
    </Dialog>
  );
};

export default ShareChatModal;
