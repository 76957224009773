import { isEmpty } from 'lodash';
import { Children, Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/Loader';
import Roles from 'src/content/Roles';
import UserGroups from 'src/content/UserGroups';
import Users from 'src/content/Users';
import Usage from 'src/content/Analytics/External';
import InternalAnalytics from 'src/content/Analytics/Internal';
import { USER_APP_PERMISSIONS } from 'src/types/enum';
import { ROUTES } from 'src/utils/constants';
import MiniExperts from 'src/content/MiniExperts';
import DocAnalyst from 'src/content/MiniExperts/DocAnalyst';
import Assistant from 'src/content/Assistant';
import { IS_MINI_EXPERTS_DISABLED } from 'src/config';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const WorkspacesContent = Loader(
  lazy(() => import('src/content/Documents/Workspaces'))
);
const CollectionsContent = Loader(
  lazy(() => import('src/content/Documents/Collections'))
);

const Documents = Loader(lazy(() => import('src/content/Documents/List')));
const Messenger = Loader(lazy(() => import('src/content/Chat')));
const Settings = Loader(lazy(() => import('src/content/Settings')));
const ShareChat = Loader(lazy(() => import('src/content/ShareChat')));

const applicationsRoutes = (userAppPermissions) => {
  const routes = [
    {
      path: '/',
      element: <Navigate to="chat" replace />
    },
    {
      path: '/chat',
      element: <Messenger />
    },
    {
      path: '/chat/:id',
      element: <Messenger />
    },
    {
      path: '/share-chat/:shareChatId',
      element: <ShareChat />
    },
    {
      path: '/assistants',
      element: <Assistant />
    }
  ];
  if (!IS_MINI_EXPERTS_DISABLED) {
    routes.push(
      {
        path: '/mini-experts',
        element: <MiniExperts />
      },
      {
        path: '/mini-experts/doc-analyst/:selectedTab',
        element: <DocAnalyst />
      }
    );
  }

  if (!isEmpty(userAppPermissions)) {
    if (userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_USERS]?.value) {
      routes.push({
        path: '/users',
        element: <Users />
      });
    }
    if (userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ROLES]?.value) {
      routes.push({
        path: '/roles',
        element: <Roles />
      });
    }
    if (
      userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ROOT_FOLDER_CONTENT]?.value
    ) {
      routes.push({
        path: `/data-library/${ROUTES.NAMESPACE_ROUTE}`,
        element: <WorkspacesContent />
      });
      routes.push({
        path: `/data-library/${ROUTES.WORKSPACE_ROUTE}/:id`,
        element: <CollectionsContent />
      });
      routes.push({
        path: `/data-library/${ROUTES.RESOURCES_ROUTE}/:id`,
        element: <Documents />
      });
      routes.push({
        path: '/data-library/*',
        element: (
          <Navigate to={`/data-library/${ROUTES.NAMESPACE_ROUTE}`} replace />
        )
      });
    }
  }
  if (
    userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_USER_GROUPS]?.value ||
    userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_OWN_USER_GROUPS]?.value
  ) {
    routes.push({
      path: '/user-groups',
      element: <UserGroups />
    });
  }
  if (userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ANALYTICS]?.value) {
    routes.push({
      path: '/usage',
      element: <Usage />
    });
    routes.push({
      path: '/settings',
      element: <Settings />
    });
    routes.push({
      path: '/internal-analytics',
      element: <InternalAnalytics />
    });
  }
  return routes;
};

export default applicationsRoutes;
