import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import { LOOKUP, RESPONSE_FEEDBACK_DETAILS } from 'src/types/enum';
import { RootState, useSelector } from 'src/redux/store';
interface FormValues {
  feedbackId: string[];
  feedbackComment: string;
}

const FeedbackDialog = ({ isOpen, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const { lookups } = useSelector((state: RootState) => state.data);
  const formMethods = useForm<FormValues>({
    defaultValues: { feedbackId: [], feedbackComment: '' },
    mode: 'onChange'
  });
  const {
    control,
    formState: { isValid },
    handleSubmit
  } = formMethods;

  const handleOnChange = (
    reactionId: string,
    field: ControllerRenderProps<FormValues, 'feedbackId'>
  ) => {
    const newValue = !field.value.includes(reactionId)
      ? [...field.value, reactionId]
      : field.value.filter((id) => id !== reactionId);
    field.onChange(newValue);
  };

  return (
    <div>
      <Dialog
        sx={{ p: 2 }}
        onClose={onClose}
        aria-labelledby="FeedbackDialog"
        open={isOpen}
        maxWidth="sm"
        fullWidth
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="FeedbackDialog">
            {t(T.feedbackTitle)}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <FormGroup>
              <FormControl>
                {lookups[LOOKUP.REACTION_DETAILS].map((reaction, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={reaction.id}
                      control={
                        <Controller
                          control={control}
                          name={`feedbackId`}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              onChange={() =>
                                handleOnChange(reaction.id, field)
                              }
                            />
                          )}
                        />
                      }
                      label={`${t(
                        T[
                          RESPONSE_FEEDBACK_DETAILS[
                            reaction.code as keyof typeof RESPONSE_FEEDBACK_DETAILS
                          ]
                        ]
                      )}`}
                    />
                  );
                })}
              </FormControl>
            </FormGroup>
            <Controller
              name="feedbackComment"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder={t(T.feedbackCommentPlaceholder)}
                  type="text"
                  fullWidth
                  {...field}
                />
              )}
            />
          </DialogContent>
          <DialogActions sx={{ p: 1 }}>
            <Button
              autoFocus
              variant="contained"
              color="secondary"
              disabled={!isValid}
              type="submit"
            >
              {t(T.submitFeedback)}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
export default FeedbackDialog;
