import { TabContext, TabPanel } from '@mui/lab';
import { Tab, Tabs, styled } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ONBOARDING_STEPS_TARGET } from 'src/components/UserOnboard/constants';
import { TENANT_NAME } from 'src/config';
import { updateChatSourceType } from 'src/redux/slices/chat';
import { useDispatch, useSelector } from 'src/redux/store';
import { CHAT_SOURCE_TYPES } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import AssistantGpt from './AssistantGpt';
import ChatGpt from './ChatGpt';
import EmpowerGpt from './EmpowerGpt';
import { ReactComponent as Workspace } from 'src/assets/Workspace.svg';
import { ReactComponent as ChatGptLogo } from 'src/assets/Globe.svg';
import { useLazyGetAssistantDetailQuery } from 'src/services/api';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { ErrorContext } from 'src/utils/errorMappings';
import {
  updateAssistantContext,
  updateInitiateAssistantState
} from 'src/redux/slices/assistant';

const StyledTab = styled(Tab)(() => ({
  fontWeight: '400 !important',
  margin: '0 2px',
  ['&.Mui-selected']: {
    borderRadius: '7.14px !important',
    boxShadow:
      '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'
  }
}));

const StyledTabPanel = styled(TabPanel)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: 'calc(100% - 60px)'
}));

const WelcomeMessage = () => {
  const { selectedChat, chatContexts } = useSelector((state) => state?.chat);
  const { assistantContext } = useSelector((state) => state?.assistant);
  const [selectedTab, setSelectedTab] = useState(CHAT_SOURCE_TYPES.EmpowerGPT);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hasAssistant = useMemo(() => {
    return assistantContext?.isAssistant;
  }, [assistantContext?.isAssistant]);

  const [fetchAssistantDetail] = useLazyQuery({
    api: useLazyGetAssistantDetailQuery,
    errorContext: ErrorContext.ASSISTANT
  });

  const handleTabChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setSelectedTab(newValue as CHAT_SOURCE_TYPES);
    dispatch(updateChatSourceType(newValue));
  };

  useEffect(() => {
    if (chatContexts[selectedChat]) {
      setSelectedTab(chatContexts[selectedChat].chat_type);
    }
  }, [selectedChat]);

  useEffect(() => {
    if (chatContexts[selectedChat]?.documentContext?.assistant_id) {
      fetchAssistantDetail({
        params: {
          params: {
            assistant_id:
              chatContexts[selectedChat]?.documentContext?.assistant_id,
            fetch_all: false
          }
        },
        successCallback(data) {
          dispatch(updateInitiateAssistantState(data?.data[0]));
          dispatch(
            updateAssistantContext({ ...assistantContext, isStartChat: true })
          );
        }
      });
    }
  }, [chatContexts[selectedChat]?.documentContext?.assistant_id]);

  return (
    <TabContext value={selectedTab}>
      {!chatContexts[selectedChat]?.documentContext?.name && !hasAssistant && (
        <Tabs
          id={ONBOARDING_STEPS_TARGET.CHAT_TYPE_BUTTONS}
          onChange={handleTabChange}
          aria-label="chat-source-selection"
          value={selectedTab}
          sx={{
            minHeight: '45px',
            maxWidth: 'max-content',
            marginLeft: '20px',
            marginTop: '13px',
            borderRadius: '7.14px',
            ['& .MuiTabs-scroller']: {
              ['& .MuiTabs-indicator']: {
                display: 'none'
              }
            }
          }}
        >
          <StyledTab
            icon={<Workspace />}
            iconPosition="start"
            label={t(T.tenantKnowledgeBase, {
              tenant: TENANT_NAME || t(T.enterprise)
            })}
            value={CHAT_SOURCE_TYPES.EmpowerGPT}
            disabled={!!selectedChat}
          />
          <StyledTab
            id={ONBOARDING_STEPS_TARGET.CHAT_WORLD_KNOWLEDGE_BUTTON}
            icon={<ChatGptLogo />}
            iconPosition="start"
            label={t(T.worldKnowledge)}
            value={CHAT_SOURCE_TYPES.ChatGPT}
            disabled={!!selectedChat}
          />
        </Tabs>
      )}
      {selectedTab === CHAT_SOURCE_TYPES.EmpowerGPT && !hasAssistant && (
        <StyledTabPanel value={CHAT_SOURCE_TYPES.EmpowerGPT}>
          <EmpowerGpt />
        </StyledTabPanel>
      )}
      {selectedTab === CHAT_SOURCE_TYPES.ChatGPT && !hasAssistant && (
        <StyledTabPanel value={CHAT_SOURCE_TYPES.ChatGPT}>
          <ChatGpt />
        </StyledTabPanel>
      )}
      {hasAssistant && (
        <StyledTabPanel value={CHAT_SOURCE_TYPES.EmpowerGPT}>
          <AssistantGpt
            assistantId={chatContexts[selectedChat]?.documentContext?.id}
            isStartChat={assistantContext?.isStartChat}
          />
        </StyledTabPanel>
      )}
    </TabContext>
  );
};

export default WelcomeMessage;
