export enum MESSAGE_TYPE {
  Q = 'q',
  R = 'r'
}

export type ChatListingProps = {
  onChatClick: (id?: number | null) => void;
  selectedChatId: number;
};

export interface IChatMessage {
  id: number;
  chat_id: number;
  message: string;
  ts: Date;
  type: MESSAGE_TYPE;
  c_ts: Date;
  history_context: number;
  reaction_id: number;
  reaction: string;
  reaction_code: string;
  reaction_details_id: number;
  reaction_details: string;
  reaction_details_code: string;
  [z: string]: any;
}

export interface IResponseFeedback {
  feedbackId: number[];
  feedbackComment?: string;
}
