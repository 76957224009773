import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as DefaultIcon } from 'src/assets/assistantIcons/default-icon.svg';
import { ReactComponent as EInitials } from 'src/assets/egpt-logo.svg';
import Markdown from 'src/components/Markdown';
import { AzureWebPubSubChatContext } from 'src/contexts/AzureWebPubSubChatContext';
import { IconResource } from 'src/redux/slices/data';
import { RootState, useSelector } from 'src/redux/store';
import { StringKeys } from 'src/types/base';
import { DATE_FORMAT } from 'src/utils/constants';
import { ErrorContext, ErrorMessages } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { formatDate, getInitials } from 'src/utils/utils';
import {
  MessagesDividerWrapper,
  PrimaryMessage,
  SecondaryMessage
} from '../styles';
import { IChatMessage, MESSAGE_TYPE } from '../types';
import { chatErrors, sortFiles } from '../utils';
import SourceItem from './SourceItem';

type ChatContentProps = {
  isDifferentDay: boolean;
  message: IChatMessage;
  isStreamingResponse?: boolean;
  isSharedChat?: boolean;
  sharedBy?: string;
  icons?: IconResource[];
  currentAssistantDetails?: StringKeys;
};

const ChatContent = ({
  message,
  isDifferentDay,
  isStreamingResponse = false,
  isSharedChat = false,
  sharedBy,
  icons,
  currentAssistantDetails
}: ChatContentProps) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);
  const { error: streamingError } = useContext(AzureWebPubSubChatContext);
  const { assistantContext, assistantObj } = useSelector(
    (state: RootState) => state.assistant
  );

  const isErrorMessage = useMemo(
    () =>
      chatErrors.includes(message?.status_details?.toUpperCase()) ||
      chatErrors.includes(streamingError?.msg?.code?.toUpperCase()),
    [message, streamingError]
  );

  const formattedMessage = useMemo(() => {
    try {
      let response = JSON.parse(message.message);
      response = !!response?.response?.length
        ? response
        : chatErrors.includes(message?.status_details?.toUpperCase())
        ? t(
            ErrorMessages[ErrorContext.AI]?.[
              message?.status_details?.toUpperCase()
            ]
          )
        : '';
      return typeof response === 'object'
        ? response
        : { response: response, files: message?.files || [] };
    } catch {
      return {
        response: message.message,
        files: message.files || []
      };
    }
  }, [message]);

  const sortedFiles = useMemo(() => {
    if (!!formattedMessage?.files?.length) {
      return sortFiles(formattedMessage);
    }
  }, [formattedMessage?.files]);

  const queryResponse = useMemo(
    () => (
      <SecondaryMessage>
        <Markdown
          messageId={message.id}
          value={formattedMessage.response}
          showCursor={isStreamingResponse}
          isDownloadingFile={isDownloadingFile}
          isSharedChat={isSharedChat}
          files={formattedMessage?.files}
          isErrorMessage={isErrorMessage}
        />
        {formattedMessage?.files?.length ? (
          <Typography style={{ paddingTop: 8, fontSize: 16, fontWeight: 500 }}>
            {t(T.sources)}:
          </Typography>
        ) : null}
        <ol>
          {!!formattedMessage?.files?.length &&
            sortedFiles?.length &&
            sortedFiles?.map(
              (
                {
                  id,
                  name,
                  source,
                  page,
                  format,
                  original_format,
                  document_id
                }: {
                  id: number;
                  name: string;
                  source: string;
                  page: number;
                  format: string;
                  original_format: string;
                  document_id?: string;
                },
                index
              ) => {
                return id ? (
                  <li key={index}>
                    <SourceItem
                      key={index}
                      id={id}
                      name={name}
                      page={page}
                      source={source}
                      format={format || ''}
                      original_format={original_format}
                      messageId={message.id}
                      documentId={document_id}
                      setIsDownloadingFile={setIsDownloadingFile}
                      isSharedChat={isSharedChat}
                    />
                  </li>
                ) : null;
              }
            )}
        </ol>
      </SecondaryMessage>
    ),
    [formattedMessage, isStreamingResponse, i18n.language, isDownloadingFile]
  );

  return (
    <Box className={`${message.id}`}>
      {isDifferentDay && (
        <MessagesDividerWrapper
          style={{
            margin: theme.spacing(1),
            marginTop: theme.spacing(3),
            ...(isSharedChat && { position: 'relative', left: '6%' })
          }}
        >
          {formatDate(message?.ts, DATE_FORMAT)}
        </MessagesDividerWrapper>
      )}
      {/* {assistantContext?.isUpdated && (
        <MessagesDividerWrapper
          style={{
            margin: theme.spacing(1),
            marginTop: theme.spacing(3),
            ...(isSharedChat && { position: 'relative', left: '6%' })
          }}
        >
          {'Assistant Updated'}
        </MessagesDividerWrapper>
      )} */}
      <Box
        display="flex"
        alignItems="flex-start"
        sx={{
          backgroundColor: `${
            message.type === MESSAGE_TYPE.Q
              ? theme.colors.custom.queryBg
              : theme.colors.custom.responseBg
          } !important`,
          [theme.breakpoints.up('md')]: {
            pl: 6,
            pr: 2
          },
          [theme.breakpoints.down('md')]: {
            px: 5
          },
          [theme.breakpoints.down('sm')]: {
            px: 1
          }
        }}
        py={3}
      >
        <Avatar
          variant="rounded"
          sx={{
            width: 50,
            height: 50,
            borderRadius: 50,
            ...(message.type === MESSAGE_TYPE.Q
              ? {
                  backgroundColor: theme.colors.custom.queryAvatarBg,
                  color: theme.colors.custom.queryAvatarFg
                }
              : {
                  backgroundColor: 'white',
                  color: '#000B4B'
                })
          }}
          alt="Advisor"
          style={{ objectFit: 'contain' }}
        >
          {message.type === MESSAGE_TYPE.Q ? (
            getInitials(
              message.type === MESSAGE_TYPE.Q && isSharedChat
                ? sharedBy
                : user.name || ''
            )
          ) : assistantContext?.isAssistant ||
            assistantContext?.isPreview ||
            currentAssistantDetails?.settings?.icon_id ? (
            assistantObj?.assistantIcon ||
            currentAssistantDetails?.settings?.icon_id ? (
              <img
                src={
                  assistantObj?.assistantIcon
                    ? assistantObj?.assistantIcon
                    : icons?.filter(
                        (icon) =>
                          icon.id ===
                          parseInt(currentAssistantDetails?.settings?.icon_id)
                      )?.[0]?.icon
                }
                alt="Assistant-Icon"
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50px'
                }}
              />
            ) : (
              <DefaultIcon
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50px',
                  padding: '10px',
                  backgroundColor: 'rgba(222, 224, 229, 0.70)'
                }}
              />
            )
          ) : (
            <EInitials />
          )}
        </Avatar>
        <Box
          display="flex"
          justifyContent={'flex-start'}
          alignItems={'center'}
          flexDirection="column"
          mr={1.5}
          ml={1.5}
          mt={0}
          sx={{
            [theme.breakpoints.down('md')]: {
              margin: 0
            }
          }}
        >
          {message.type === MESSAGE_TYPE.R ? (
            queryResponse
          ) : (
            <PrimaryMessage>
              <pre
                style={{
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'inherit',
                  wordWrap: 'break-word',
                  margin: 0
                }}
              >
                {message.message}
              </pre>
            </PrimaryMessage>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ChatContent;
