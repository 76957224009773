import { get } from 'react-hook-form';
import {
  FilterComponents,
  FilterElementTypes
} from 'src/types/custom_component';

export const getDefaultValues = (
  item: FilterComponents,
  forceReset?: boolean
) => {
  return item.defaultValue && !forceReset
    ? item.defaultValue
    : item.type === FilterElementTypes.Select
    ? SelectAllItem
    : item.type === FilterElementTypes.MultiAutoComplete
    ? []
    : item.type === FilterElementTypes.AutoComplete
    ? null
    : item.type === FilterElementTypes.SearchAutoComplete
    ? ''
    : item.type === FilterElementTypes.DropDown
    ? ''
    : null;
};

export const SelectAllItem = { name: 'All', value: 'all', id: 0 };

export const getValues = (
  componentsValue: any,
  filteringKeys: any,
  elementsList: FilterComponents[]
) => {
  Object.keys(componentsValue).forEach((key: string) => {
    let component: FilterComponents | undefined = elementsList.find(
      (item) => item.dataField === key
    );
    let type: FilterElementTypes | '' = component ? component.type : '';

    if (componentsValue[key]) {
      if (
        (component && type === FilterElementTypes.AutoComplete) ||
        component.datasource
      ) {
        let selected: any = componentsValue[key];
        component.datasource.forEach((item: any) => {
          if (item.id === componentsValue[key].id) {
            selected = item;
          }
        });

        filteringKeys[key] = selected;
      } else if (type === FilterElementTypes.MultiAutoComplete) {
        if (componentsValue[key].length) {
          filteringKeys[key] = componentsValue[key];
        }
      } else if (type === FilterElementTypes.Select) {
      } else if (type === FilterElementTypes.SearchAutoComplete) {
        if (Array.isArray(componentsValue[key])) {
          if (component.valueKey)
            filteringKeys[key] = componentsValue[key]
              .map((item) => get(item, component.valueKey))
              .toString();
          else {
            filteringKeys[key] = componentsValue[key];
          }
        } else {
          if (component.valueKey) {
            filteringKeys[key] = get(componentsValue[key], component.valueKey);
          } else {
            filteringKeys[key] = componentsValue[key];
          }
        }
      } else {
        filteringKeys[key] = componentsValue[key];
      }
    }
  });
};

export const getSelectedFilters = (filters: {}) => {
  let isFilterSelected = false;
  let selectedFilters = [];
  Object.entries(filters).forEach(([key, value]) => {
    if (key !== 's') {
      if (!Array.isArray(value) && !!value && value !== '') {
        isFilterSelected = true;
        selectedFilters.push(value);
      }
      if (Array.isArray(value) && value.length > 0) {
        isFilterSelected = true;
        selectedFilters.push(value);
      }
    }
  });

  return { isFilterSelected, selectedFilters };
};
