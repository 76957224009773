import { ArticleOutlined } from '@mui/icons-material';
import { ReactComponent as DefaultIcon } from 'src/assets/assistantIcons/default-icon.svg';
import { IconResource } from 'src/redux/slices/data';
import { initialColorList } from 'src/theme/customColors';
import { AiModel, StringKeys } from 'src/types/base';
import {
  FAVOURITE_TYPE,
  RESOURCE_PROVIDERS,
  RESOURCE_TYPE
} from 'src/types/enum';
import { getInitials } from 'src/utils/utils';
import {
  AssistantIconContainer,
  CustomCollectionIcon,
  CustomFolderIcon,
  OneDriveCollectionIcon,
  OneDriveFolderIcon,
  SharePointCollectionIcon,
  SharePointFolderIcon
} from '../styles';

const WorkspaceIcon = ({ item }) => {
  return (
    <div
      style={{
        backgroundColor: item?.initials_color || initialColorList[0],
        border:
          !item?.initials_color ||
          item?.initials_color === initialColorList[0] ||
          item?.initials_color === '#000C57'
            ? '1px solid white'
            : 'none',
        borderRadius: '4px',
        height: '28px',
        width: '28px',
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      aria-haspopup="true"
    >
      {item?.initials || getInitials(item.display_name)}
    </div>
  );
};

const AssistantIcon = ({ icons, item }) => {
  return (
    <AssistantIconContainer
      style={{
        backgroundImage: `url(${
          icons?.filter((icon) => icon.id == item?.settings?.icon_id)?.[0]
            ?.icon || ''
        })`
      }}
    />
  );
};

const defaultItem = {
  icon: ArticleOutlined,
  link: '/data-library'
};

const getItemData = (
  item: StringKeys,
  favouriteType: string,
  icons?: IconResource[],
  configuredModels?: AiModel[]
) => {
  let itemData;
  if (favouriteType === FAVOURITE_TYPE.ASSISTANT) {
    itemData = {
      hasPermission: !(
        (item?.settings?.web_search_enabled ||
          item?.has_permission ||
          item?.settings?.world_knowledge) &&
        !!configuredModels.filter(
          (model) => model.id === parseInt(item?.settings.model_id)
        )[0]
      )
    };
    if (item?.settings?.icon_id) {
      itemData = {
        ...itemData,
        icon: () => <AssistantIcon icons={icons} item={item} />
      };
    } else {
      itemData = {
        ...itemData,
        icon: DefaultIcon
      };
    }
  } else {
    switch (item.code) {
      case RESOURCE_TYPE.WORKSPACE:
      case RESOURCE_TYPE.PUBLIC_WORKSPACE:
      case RESOURCE_TYPE.FOUND_WORKSPACE:
      case RESOURCE_TYPE.PRIVATE_WORKSPACE:
        itemData = {
          icon: () => <WorkspaceIcon item={item} />,
          link: `/data-library/collections/${item.item_id}`
        };
        break;
      case RESOURCE_TYPE.COLLECTION:
      case RESOURCE_TYPE.FOUND_COLLECTION:
        itemData = {
          icon:
            item?.provider_type === RESOURCE_PROVIDERS.ONEDRIVE
              ? OneDriveCollectionIcon
              : item?.provider_type === RESOURCE_PROVIDERS.SHAREPOINT
              ? SharePointCollectionIcon
              : CustomCollectionIcon,
          link: `/data-library/resources/${item.item_id}`
        };
        break;
      case RESOURCE_TYPE.FOLDER:
        itemData = {
          icon:
            item?.provider_type === RESOURCE_PROVIDERS.ONEDRIVE
              ? OneDriveFolderIcon
              : item?.provider_type === RESOURCE_PROVIDERS.SHAREPOINT
              ? SharePointFolderIcon
              : CustomFolderIcon,
          link: `/data-library/resources/${item.item_id}`
        };
        break;
      default:
        itemData = defaultItem;
    }
  }

  return itemData;
};

export default getItemData;
