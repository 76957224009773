import { Box } from '@mui/material';
import DateFilter from '../../utils/Filters/DateFilter';
import ClearButton from '../../utils/Filters/ClearButton';
import ActionButton from '../../utils/Filters/ActionButton';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import SearchInput from '../../utils/Filters/SearchInput';

const Filters = ({
  search,
  setSearch,
  dateCreated,
  setDateCreated,
  clearAllFilters,
  handleCreateQuestionnaire
}) => {
  const { t } = useTranslation();

  const isFilterApplied = search !== '' || dateCreated !== null;

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" gap="16px">
        <SearchInput value={search} onChange={setSearch} />

        <DateFilter
          placeholder={t(T.createdOn)}
          value={dateCreated}
          onChange={setDateCreated}
        />

        <ClearButton onClick={clearAllFilters} disabled={!isFilterApplied} />
      </Box>

      <ActionButton
        text={t(T.addQuestionnaire)}
        onClick={handleCreateQuestionnaire}
      />
    </Box>
  );
};

export default Filters;
