import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { ReactComponent as Logo } from 'src/assets/empowergpt-logo.svg';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import WarningRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import QuestionCard from './QuestionCard';
import { ReactComponent as CapabilitiesIcon } from 'src/assets/capabilities.svg';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';

const EmpowerGpt = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Stack
      id="test"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        gap: '30px'
      }}
    >
      <Box paddingX={theme.spacing(2.5)}>
        <Logo width={230} height={80} />
      </Box>
      <Box>
        <Typography fontSize={'17px'} lineHeight={'24px'}>
          {t(T.empowerGptSlogan)}
        </Typography>
      </Box>
      <Grid
        container
        maxWidth={'md'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        width={'80%'}
        marginX={'auto'}
        marginTop={theme.spacing(2)}
      >
        <Grid
          item
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          xs={12}
          md={4}
          paddingX={theme.spacing(1)}
          paddingTop={theme.spacing(1.5)}
          gap={1}
        >
          <LightModeOutlinedIcon
            fontSize="large"
            htmlColor={theme.colors.secondary.dark}
          />
          <Box>
            <Typography variant="h4">{t(T.samplePrompts)}</Typography>
          </Box>
          <Box>
            <QuestionCard message={t(T.samplePrompt1)} />
          </Box>
          <Box>
            <QuestionCard message={t(T.samplePrompt2)} />
          </Box>
        </Grid>
        <Grid
          item
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          xs={12}
          md={4}
          paddingX={theme.spacing(1)}
          paddingTop={theme.spacing(1.5)}
          gap={1}
        >
          <CapabilitiesIcon />
          <Box>
            <Typography variant="h4">{t(T.capabilities)}</Typography>
          </Box>
          <Box>
            <QuestionCard message={t(T.capabilities1)} />
          </Box>
          <Box>
            <QuestionCard message={t(T.capabilities2)} />
          </Box>
        </Grid>
        <Grid
          item
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          xs={12}
          md={4}
          paddingX={theme.spacing(1)}
          paddingTop={theme.spacing(1.5)}
          gap={1}
        >
          <WarningRoundedIcon
            fontSize="large"
            htmlColor={theme.colors.secondary.dark}
          />
          <Box>
            <Typography variant="h4">{t(T.limitations)} </Typography>
          </Box>
          <Box>
            <QuestionCard message={t(T.limitations1)} />
          </Box>
          <Box>
            <QuestionCard message={t(T.limitations2)} />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default EmpowerGpt;
