import { useCallback, useEffect } from 'react';
import { RootState, useDispatch, useSelector } from '../redux/store';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import {
  updateLookups,
  updateUserLanguage,
  setUserAppPermissions,
  setUserResourceProvider,
  setUserIdentityProviders,
  setUserFavourites,
  updateUserOnboarding,
  updateConfiguredModels,
  updateUserDefaultModel,
  setIcons
} from 'src/redux/slices/data';
import {
  useLazyGetLookupsQuery,
  useLazyGetUserPreferencesQuery,
  useLazyGetPermissionsQuery,
  useLazyGetIdentityProviderQuery,
  useLazyGetResourceProviderQuery,
  useLazyGetFavouriteQuery,
  useLazyGetAppSettingsQuery,
  useLazyGetModelsQuery,
  useUpdateAppSettingsMutation,
  useLazyGetIconsQuery
} from 'src/services/api';
import { AiModel, StringKeys } from 'src/types/base';
import initializei18n from '../i18n/i18n';
import { LOOKUP, PERMISSION_TYPE } from 'src/types/enum';
import useLazyQuery from './useLazyQuery';
import { DataApiInputParams } from 'src/types/api';
import { ErrorContext } from 'src/utils/errorMappings';
import {
  IGetConfiguredModelsResponse,
  IGetIdentityProviderResponse
} from 'src/types/settings';
import useMutation from './useMutation';

const FILE_TAGS_POLLING_TIME = 1000 * 60 * 2;
function useGlobalData() {
  const dispatch = useDispatch();
  const { userAIModel } = useSelector((state) => state.data);
  const { tenant_info } = useSelector((state: RootState) => state.library);
  // const [fetchTags, { data: tagsList }] = useLazyQuery<
  //   DataApiInputParams,
  //   StringKeys
  // >(useLazyGetTagsQuery);
  // const [fetchFilesTags, { data: allFileTags }] = useLazyQuery<
  //   DataApiInputParams,
  //   StringKeys
  // >(useLazyGetFilesTagsQuery, {
  //   pollingInterval: FILE_TAGS_POLLING_TIME
  // });
  const [fetchLookups, { data: lookups }] = useLazyQuery<
    DataApiInputParams,
    StringKeys
  >({ api: useLazyGetLookupsQuery });
  const [fetchUserAppPermissions, { data: userAppPermissions }] = useLazyQuery<
    DataApiInputParams,
    StringKeys
  >({ api: useLazyGetPermissionsQuery });
  const [getUserPreferences, { data: userPreferences }] = useLazyQuery<
    DataApiInputParams,
    StringKeys
  >({ api: useLazyGetUserPreferencesQuery });
  const [fetchResourceProviders, { data: userProviders }] = useLazyQuery<
    void,
    unknown
  >({
    api: useLazyGetResourceProviderQuery,
    errorContext: ErrorContext.GENERAL
  });
  const [fetchIdentityProviders, { data: identityProviders }] = useLazyQuery<
    void,
    IGetIdentityProviderResponse
  >({ api: useLazyGetIdentityProviderQuery });
  const [fetchFavourites, { data: favourites }] = useLazyQuery({
    api: useLazyGetFavouriteQuery
  });
  const [getConfiguredModels, { data: configuredModels }] = useLazyQuery<
    DataApiInputParams,
    IGetConfiguredModelsResponse
  >({
    api: useLazyGetAppSettingsQuery
  });

  const [fetchAllAiModels, { data: aiModels, isFetching: isFetchingModels }] =
    useLazyQuery<DataApiInputParams, any>({
      api: useLazyGetModelsQuery
    });

  const [updateConfiguredModelsApi] = useMutation({
    api: useUpdateAppSettingsMutation,
    errorContext: ErrorContext.GENERAL
  });

  const [fetchIcons, {data: iconData}] = useLazyQuery({
    api: useLazyGetIconsQuery,
    errorContext: ErrorContext.ICONS
  });

  const initialize = useCallback(() => {
    getUserPreferences({
      params: { params: { settingsList: ['lang ,model, onboarded'] } }
    });
    fetchUserAppPermissions({
      params: {
        params: {
          ptype: PERMISSION_TYPE.USER_APP_PERMISSIONS
        }
      }
    });
    fetchLookups({
      params: {
        params: { lookup_types: [...Object.values(LOOKUP)].toString() }
      }
    });
    fetchIcons({ params: undefined });
    fetchResourceProviders({ params: undefined });
    fetchIdentityProviders({ params: undefined });
    fetchFavourites({ params: undefined });
  }, []);

  useEffect(() => {
    if (
      tenant_info &&
      Object.keys(tenant_info).length &&
      userPreferences?.data
    ) {
      getConfiguredModels({
        params: {
          params: {
            rid_list: tenant_info['default_application']['id'],
            setting_list: 'models'
          }
        }
      });
    }
  }, [tenant_info, userPreferences]);

  useEffect(() => {
    if (lookups) {
      dispatch(updateLookups(lookups));
    }
  }, [lookups]);

  useEffect(() => {
    if (userAppPermissions) {
      dispatch(setUserAppPermissions(userAppPermissions));
    }
  }, [userAppPermissions]);

  useEffect(() => {
    if (favourites?.data) {
      dispatch(setUserFavourites(favourites?.data));
    }
  }, [favourites]);

  useEffect(() => {
    if (userProviders) {
      dispatch(
        setUserResourceProvider(
          (
            userProviders as {
              data: {
                resource_provider: StringKeys;
                resource_provider_settings: StringKeys[];
              }[];
            }
          )?.data?.map((item) => ({
            ...item.resource_provider,
            ...item.resource_provider_settings.reduce(
              (acc, { key, value }) => ({ ...acc, [key]: value }),
              {}
            )
          }))
        )
      );
    }
  }, [userProviders]);

  useEffect(() => {
    if (identityProviders) {
      dispatch(setUserIdentityProviders(identityProviders.data));
    }
  }, [identityProviders]);

  useEffect(() => {
    if (iconData) {
      dispatch(setIcons(iconData?.data));
    }
  }, [iconData]);

  useEffect(() => {
    if (userPreferences?.data) {
      const getPreference = (key: string) =>
        userPreferences?.data?.find((item) => item.name === key)?.value;

      let lang = getPreference('lang');
      let userAIModel = getPreference('model');
      let isUserOnboarded = getPreference('onboarded');
      isUserOnboarded = (isUserOnboarded || '') === 'true' ? true : false;
      dispatch(updateUserOnboarding(isUserOnboarded));
      if (lang) {
        initializei18n.changeLanguage(lang);
      }
      if (userAIModel) {
        dispatch(updateUserDefaultModel(JSON.parse(userAIModel)));
      }
      dispatch(updateUserLanguage(lang));
    }
  }, [userPreferences]);

  useEffect(() => {
    if (configuredModels?.data) {
      let modelData: string = configuredModels?.data[0]?.value;
      let appModels: AiModel[] = modelData ? JSON.parse(modelData) : [];

      if (!appModels.length) {
        fetchAllAiModels({ params: undefined });
      } else {
        if (appModels) {
          appModels = [...appModels].sort((a, b) => a.priority - b.priority);
          dispatch(updateConfiguredModels(appModels));
          dispatch(updateUserDefaultModel(appModels[0]));
        }
      }
    }
  }, [configuredModels]);

  useEffect(() => {
    if (aiModels?.data) {
      const defaultModel = aiModels?.data?.length ? [aiModels?.data[0]] : [];
      updateConfiguredModelsApi({
        params: {
          params: {
            name: 'models',
            v: JSON.stringify(defaultModel),
            rid: tenant_info['default_application']['id']
          }
        },
        fallbackMsg: T.internalServerError
      });
      dispatch(updateConfiguredModels(defaultModel));
      dispatch(updateUserDefaultModel(defaultModel[0]));
    }
  }, [aiModels]);

  return { initialize };
}

export default useGlobalData;
