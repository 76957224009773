import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { CHAT_LIST_PAGE_SIZE } from 'src/content/Chat/utils';
import { AiModel } from 'src/types/base';
import { ChatData } from 'src/types/chat';
import { PdfDocument } from 'src/types/document';
import { CHAT_CLASS, CHAT_SOURCE_TYPES } from 'src/types/enum';
import { v4 as uuidV4 } from 'uuid';

interface StreamEventTimings {
  querySendTime?: number;
  queryAcknowledgeTime?: number;
  responseStreamStartTime?: number;
  responseStreamEndTime?: number;
}

interface ChatContext {
  tags: any[];
  openedDocuments: PdfDocument[];
  isDocumentViewerOpen: boolean;
  selectedDocumentIndex: number;
  streamEventTimings: StreamEventTimings;
  chat_type: CHAT_SOURCE_TYPES;
  chatModel: AiModel;
  messageId?: number;
  documentContext?: {
    id: number;
    name: string;
    workspaceName?: string;
    assistant_id?: number;
  };
  shareChatData?: {
    link: string;
    sharedOn: string;
  };
  web_search_enabled?: boolean;
  chat_class?: CHAT_CLASS;
  assistant_id?: number;
  ts?: string;
}

const initialState: {
  selectedChat: number;
  isParameterModalOpen: boolean;
  chats: ChatData[];
  chatContexts: {
    [id: number]: ChatContext;
  };
  currentlyDownloadingDocuments: number[];
  currentPageNumber: number;
  showChatHistoryPan: boolean;
} = {
  chats: [],
  selectedChat: undefined,
  isParameterModalOpen: false,
  chatContexts: {},
  currentlyDownloadingDocuments: [],
  currentPageNumber: 0,
  showChatHistoryPan: false
};

export const defaultChatContext = {
  tags: [],
  openedDocuments: [],
  isDocumentViewerOpen: false,
  selectedDocumentIndex: 0,
  streamEventTimings: {},
  chat_type: CHAT_SOURCE_TYPES.EmpowerGPT,
  chatModel: null,
  web_search_enabled: false,
  chat_class: CHAT_CLASS?.NORMAL
};

const ChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChats(state, { payload }) {
      state.chats = payload;
    },
    updateChatData(
      state,
      { payload }: { payload: { chatId: number; chatData: any } }
    ) {
      const { chatId, chatData } = payload;
      const chatIndex = findIndex(state.chats, { id: chatId });
      if (chatIndex > -1) {
        state.chats[chatIndex] = { ...state.chats[chatIndex], ...chatData };
      }
    },
    appendChat(state, { payload }: { payload: ChatData }) {
      if (
        state.chats.length >
        CHAT_LIST_PAGE_SIZE * (state.currentPageNumber + 1)
      ) {
        state.chats.splice(state.chats.length - 1, 1);
      }
      state.chats.unshift(payload);
    },
    setSelectedChat(state, action) {
      state.selectedChat = action.payload;
    },
    openParameterModal(state) {
      state.isParameterModalOpen = true;
    },
    closeParameterModal(state) {
      state.isParameterModalOpen = false;
    },
    createChatSnapshot(
      state,
      { payload }: { payload: { chatId: number; chatContext?: ChatContext } }
    ) {
      const { chatId, chatContext } = payload;
      const selectedChatContext = state.chatContexts[chatId];
      if (
        !selectedChatContext ||
        selectedChatContext?.documentContext?.id !==
          chatContext?.documentContext?.id
      ) {
        state.chatContexts = {
          ...state.chatContexts,
          [chatId]: chatContext ? { ...chatContext } : defaultChatContext
        };
      }
    },
    updateChatTags(state, { payload }) {
      if (state.chatContexts[payload.chatId])
        state.chatContexts[payload.chatId].tags = payload.tags;
    },
    updateChatModel(
      state,
      {
        payload
      }: {
        payload: {
          chatId: number;
          chatModel: AiModel;
        };
      }
    ) {
      state.chatContexts[payload.chatId].chatModel = payload.chatModel;
    },
    clearChatSnapshot(state, { payload }: { payload: number }) {
      if (state.chatContexts[payload]) {
        delete state.chatContexts[payload];
      }
    },
    updateChatSourceType(state, { payload }) {
      state.chatContexts[0].chat_type = payload;
    },
    updateOpenedDocument(
      state,
      {
        payload
      }: {
        payload: {
          chatId: number;
          document: {
            pageNumber: number;
            name: string;
            id: number;
            index: number;
          };
        };
      }
    ) {
      if (
        state.chatContexts[payload.chatId].openedDocuments[
          payload.document.index
        ].pageNumber !== payload.document.pageNumber
      ) {
        state.chatContexts[payload.chatId].openedDocuments[
          payload.document.index
        ] = {
          ...state.chatContexts[payload.chatId].openedDocuments[
            payload.document.index
          ],
          ...payload.document
        };
      }
      state.chatContexts[payload.chatId].isDocumentViewerOpen = true;
      if (
        state.chatContexts[payload.chatId].selectedDocumentIndex !==
        payload.document.index
      ) {
        state.chatContexts[payload.chatId].selectedDocumentIndex =
          payload.document.index;
      }
    },
    addOpenedDocument(
      state,
      {
        payload
      }: {
        payload: {
          messageId?: number;
          chatId: number;
          document: PdfDocument;
        };
      }
    ) {
      let uuid = uuidV4();

      state.chatContexts[payload.chatId].openedDocuments.unshift({
        ...payload.document,
        uuid: uuid
      });
      state.chatContexts[payload.chatId].isDocumentViewerOpen = true;
      state.chatContexts[payload.chatId].messageId = payload.messageId;
    },
    removeOpenedDocument(
      state,
      {
        payload
      }: {
        payload: { chatId: number; documentIndex: number };
      }
    ) {
      state.chatContexts[payload.chatId].openedDocuments.splice(
        payload.documentIndex,
        1
      );
      if (!state.chatContexts[payload.chatId].openedDocuments.length) {
        state.chatContexts[payload.chatId].isDocumentViewerOpen = false;
      }
    },
    openDocumentViewer(state, { payload }: { payload: number }) {
      state.chatContexts[payload].isDocumentViewerOpen = true;
    },
    closeDocumentViewer(state, { payload }: { payload: number }) {
      if (payload in state.chatContexts) {
        state.chatContexts[payload].isDocumentViewerOpen = false;
        state.chatContexts[payload].openedDocuments = [];
      }
    },
    addCurrentlyDownloadingDocuments(state, { payload }: { payload: number }) {
      state.currentlyDownloadingDocuments.push(payload);
    },
    removeCurrentlyDownloadingDocuments(
      state,
      { payload }: { payload: number }
    ) {
      state.currentlyDownloadingDocuments =
        state.currentlyDownloadingDocuments.filter((item) => item !== payload);
    },
    updateStreamEventTimings(
      state,
      {
        payload
      }: { payload: { chatId: number; streamEventTimings: StreamEventTimings } }
    ) {
      const { chatId, streamEventTimings } = payload;
      if (state.chatContexts[chatId])
        state.chatContexts = {
          ...state.chatContexts,
          [chatId]: {
            ...state.chatContexts[chatId],
            streamEventTimings: { ...streamEventTimings }
          }
        };
    },
    createShareChat(
      state,
      {
        payload
      }: {
        payload: {
          chatId: number;
          shareChatData: { link: string; sharedOn: string };
        };
      }
    ) {
      const { chatId, shareChatData } = payload;
      if (state.chatContexts[chatId])
        state.chatContexts = {
          ...state.chatContexts,
          [chatId]: {
            ...state.chatContexts[chatId],
            shareChatData: { ...shareChatData }
          }
        };
    },
    updateChatsPageNumber(state, { payload }) {
      state.currentPageNumber = payload || 0;
    },
    toggleChatHistoryPan(state) {
      state.showChatHistoryPan = !state.showChatHistoryPan;
    }
  }
});

export default ChatSlice;

export const reducer = ChatSlice.reducer;

export const {
  setChats,
  updateChatData,
  appendChat,
  setSelectedChat,
  openParameterModal,
  closeParameterModal,
  createChatSnapshot,
  updateChatTags,
  updateChatModel,
  clearChatSnapshot,
  addOpenedDocument,
  updateOpenedDocument,
  removeOpenedDocument,
  openDocumentViewer,
  closeDocumentViewer,
  addCurrentlyDownloadingDocuments,
  removeCurrentlyDownloadingDocuments,
  updateStreamEventTimings,
  updateChatSourceType,
  createShareChat,
  updateChatsPageNumber,
  toggleChatHistoryPan
} = ChatSlice.actions;
