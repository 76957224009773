import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import ItemWithDetails from 'src/components/ItemWithDetails';
import CustomAutoComplete from 'src/components/CustomAutoComplete';
import { selectModel } from 'src/redux/slices/docAnalyst';
import { Box, Typography } from '@mui/material';

const Models = ({ isDisabled }: { isDisabled: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    initiateAnalysis: { selectedModel }, availableModels
  } = useSelector((state) => state.docAnalyst);

  useEffect(() => {
    if (availableModels.length && !selectedModel) {
      const defaultModel = availableModels.find(
        (model) => model.name === 'gpt-4-32k'
      );
      dispatch(selectModel(defaultModel));
    }
  }, [availableModels]);

  const onChangeModel = (event, newValue) => {
    dispatch(selectModel(newValue));
  };

  const renderModelUi = useCallback((props, option) => {
    return (
      <li {...props}>
        <ItemWithDetails
          name={option?.name}
          displayName={option?.display_name}
          comment={option?.comment}
          showCheckbox={false}
          imageSize={'small'}
        />
      </li>
    );
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <Typography height="26px">Models</Typography>
      <CustomAutoComplete
        multiple={false}
        readOnly={true}
        label="ai-models"
        placeholder={t(T.selectModels)}
        dataSource={availableModels}
        onChange={onChangeModel}
        value={selectedModel || ''}
        optionLabel={'display_name'}
        renderOption={renderModelUi}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={{
          width: '200px',
          flexGrow: '1',
          '& .MuiAutocomplete-popupIndicator': {
            color: '#26389A!important',
            ':hover': {
              background: 'none!important'
            }
          },
          '.MuiAutocomplete-popupIndicator.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.38)!important'
          },
          '& .MuiFormControl-root': {
            height: '100%'
          },
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            fontSize: '14px',
            fontWeight: '400',
            height: '100%',
            textFillColor: '#222F59'
          }
        }}
        disableSearch={isDisabled}
        disabled={isDisabled}
        disableClearable
        popperWidth={270}
        popperMaxHeight={360}
        optionStyles={true}
        disabledInputStroke={'rgba(0, 0, 0, 0.38)'}
      />
    </Box>
  );
};

export default Models;
