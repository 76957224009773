import { ArticleOutlined } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ChatGptLogo } from 'src/assets/chatgpt-logo-filled.svg';
import { ONBOARDING_STEPS_TARGET } from 'src/components/UserOnboard/constants';
import useLazyQuery from 'src/hooks/useLazyQuery';
import {
  addCurrentlyDownloadingDocuments,
  addOpenedDocument,
  removeCurrentlyDownloadingDocuments,
  updateOpenedDocument
} from 'src/redux/slices/chat';
import { addDocument } from 'src/redux/slices/data';
import { RootState } from 'src/redux/store';
import { useLazyDownloadFileQuery } from 'src/services/api';
import { DataApiInputParams } from 'src/types/api';
import { SourceItemProps } from 'src/types/custom_component';
import { FILE_EXTENSIONS, SUPPORTED_UPLOAD_FILES_FORMAT } from 'src/types/enum';
import { ErrorContext } from 'src/utils/errorMappings';
import { downloadFileToSystem } from 'src/utils/files/file_encryption';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { getFileIcon, getFileType } from 'src/utils/utils';

const SourceItem = ({
  id,
  name,
  page,
  source,
  format,
  original_format,
  messageId,
  documentId,
  setIsDownloadingFile,
  isSharedChat
}: SourceItemProps) => {
  const { t }: { t: any } = useTranslation();

  const dispatch = useDispatch();
  const documents = useSelector((state: RootState) => state.data.documents);
  const [downloadFile, { data, isLoading, isError, isFetching }] = useLazyQuery<
    DataApiInputParams,
    any
  >({ api: useLazyDownloadFileQuery, errorContext: ErrorContext.FILES });
  const [fileType] = useState(
    getFileType(name, source, format, original_format)
  );

  const {
    selectedChat: chatId,
    chatContexts,
    currentlyDownloadingDocuments,
    chats
  } = useSelector((state: RootState) => state.chat);

  const isWebSearch = useMemo(
    () =>
      chatId
        ? chats?.find((item) => item?.id === chatId)?.web_search_enabled
        : false,
    [chatId, chats]
  );

  useEffect(() => {
    if (data && !isFetching) {
      const blob = new Blob([data.blob], {
        type: data?.contentType
      });
      if (data?.format === FILE_EXTENSIONS.PDF) {
        dispatch(addDocument({ id, file: blob }));
        dispatch(
          addOpenedDocument({
            messageId,
            chatId: +chatId,
            document: { name, pageNumber: page, id, pdfData: blob }
          })
        );
      } else {
        dispatch(addDocument({ id, file: blob }));
        downloadFileToSystem(blob, name);
      }
      dispatch(removeCurrentlyDownloadingDocuments(id));
    }
  }, [data, isFetching]);

  useEffect(() => {
    setIsDownloadingFile(isLoading);
  }, [isLoading]);

  const updateOtherSource = () =>
    downloadFileToSystem(documents[id].file, name);

  const updatePdfSource = () => {
    const docIndex = chatContexts[chatId].openedDocuments.findIndex(
      (doc) => doc.id === id
    );
    if (docIndex < 0) {
      dispatch(
        addOpenedDocument({
          messageId,
          chatId: +chatId,
          document: {
            name,
            pageNumber: page,
            id,
            pdfData: documents[id].file
          }
        })
      );
    } else {
      dispatch(
        updateOpenedDocument({
          chatId: +chatId,
          document: {
            name,
            pageNumber: page,
            id,
            index: docIndex
          }
        })
      );
    }
  };

  const getStartIcon = useMemo(() => {
    const Icon = getFileIcon(fileType);
    if (isWebSearch) {
      return <ChatGptLogo height={20} width={20} />;
    }
    if (Icon) {
      return <Icon height={20} width={20} />;
    }
    return <ArticleOutlined fontSize="small" />;
  }, [fileType]);

  const handleDownloadClick = useCallback(() => {
    if (isWebSearch) {
      window.open(source, '_blank', 'noopener,noreferrer');
    } else {
      if (id in documents) {
        // TODO: check if we need this condition
        if (documents[id]?.file?.type === SUPPORTED_UPLOAD_FILES_FORMAT.PDF) {
          updatePdfSource();
        } else {
          updateOtherSource();
        }
      } else {
        dispatch(addCurrentlyDownloadingDocuments(id));
        downloadFile({ params: { params: { id } } });
      }
    }
  }, [documents, chatContexts, chatId, isWebSearch]);

  return (
    <LoadingButton
      className={ONBOARDING_STEPS_TARGET.CHAT_REFERENCE_DOCUMENT}
      id={documentId ? `${messageId}+${documentId}` : undefined}
      key={id}
      startIcon={getStartIcon}
      loading={isLoading}
      onClick={handleDownloadClick}
      loadingPosition="start"
      style={{
        justifyContent: 'flex-start',
        textAlign: 'start'
      }}
      disabled={
        !name ||
        isLoading ||
        currentlyDownloadingDocuments.includes(id) ||
        isSharedChat
      }
    >
      <Typography
        sx={{
          width: '100%',
          textAlign: 'left',
          fontWeight: 'bold'
        }}
        variant="body2"
      >
        {name
          ? isWebSearch
            ? `${name}`
            : `${name} (page: ${page})`
          : `[${t(T.resourceUnavailable)}]`}
      </Typography>
    </LoadingButton>
  );
};

export default SourceItem;
