import { Box, Chip, Divider, Tooltip } from '@mui/material';

type ResourceInfoProps = {
  resourceName: string;
  isError: boolean;
  resourceNameTooltip?: string;
};

const ResourceInfo = ({
  resourceNameTooltip,
  resourceName,
  isError
}: ResourceInfoProps) => {
  return (
    <>
      <Box display={'flex'} sx={{ ml: 1, height: '23px' }}>
        <Tooltip title={resourceNameTooltip || ''} placement="top">
          <Chip
            label={resourceName}
            color={isError ? 'error' : 'default'}
            size={'small'}
            icon={null}
            sx={{
              maxWidth: '200px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          />
        </Tooltip>
      </Box>
      <Divider
        orientation="vertical"
        sx={{
          height: '30px'
        }}
      />
    </>
  );
};

export default ResourceInfo;
