import { getEncryptParams } from 'src/keycloak';
import { DataApiInputParams } from 'src/types/api';
import {
  Builder,
  appendFormData,
  decryptResponse,
  getHeaders,
  getQueryString,
  serializeErrorResponse
} from './utils';
import { IS_ENCRYPTION_ENABLED } from 'src/config';

export default (builder: Builder) => {
  return {
    getIcons: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const decryptKey = getEncryptParams()?.k;
        const url = `app/icons?${getQueryString(arg.params)}`;
        const result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['Icon']
    }),
    uploadIcon: builder.mutation({
      async queryFn(arg: DataApiInputParams, _, __, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;

        let formData = appendFormData(arg.params);
        let url = `app/icons`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Icon']
    }),
    deleteIcon: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `app/icons`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'DELETE',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Icon']
    })
  };
};
