import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DefaultIcon } from 'src/assets/assistantIcons/default-icon.svg';
import useLazyQuery from 'src/hooks/useLazyQuery';
import {
  updateInitiateAssistantState,
  updateStarterQuestion
} from 'src/redux/slices/assistant';
import { useDispatch, useSelector } from 'src/redux/store';
import { useLazyGetAssistantQuery } from 'src/services/api';
import { ErrorContext } from 'src/utils/errorMappings';
import QuestionCard from './QuestionCard';

const AssistantGpt = ({ assistantId, isStartChat }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { assistantObj, assistantContext } = useSelector(
    (state) => state.assistant
  );
  const { configuredModels, icons } = useSelector((state) => state.data);
  const [assistantDetails, setAssistantDetails] = useState<any>();
  const [starters, setStarters] = useState<any>([]);
  const dispatch = useDispatch();
  const [fetchAssistant] = useLazyQuery({
    api: useLazyGetAssistantQuery,
    errorContext: ErrorContext.ASSISTANT
  });

  useEffect(() => {
    if (isStartChat && assistantId) {
      fetchAssistant({
        params: {
          params: {
            id: assistantId
          }
        },
        successCallback(data) {
          setAssistantDetails(data?.data?.[0]);
          setStarters(data?.data?.[0]?.settings?.starters);
          let temp = {
            ...assistantObj,
            aiModel: configuredModels.filter(
              (model) =>
                model.id === parseInt(data?.data?.[0]?.settings?.model_id)
            )?.[0],
            isWebSearch: data?.data?.[0]?.settings?.web_search_enabled
          };
          dispatch(updateInitiateAssistantState(temp));
        }
      });
    } else {
      setAssistantDetails(assistantObj);
      setStarters(assistantObj?.conversationStarter || []);
    }
  }, [assistantObj]);

  const handleQuestionCardClick = useCallback(
    (index) => {
      let tempQuestion: any =
        assistantObj?.conversationStarter?.[index] || starters?.[index];

      dispatch(updateStarterQuestion(tempQuestion?.name || tempQuestion));
    },
    [assistantObj, starters]
  );

  return (
    <Stack
      id="assistant-gpt-welcome-screen"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        gap: '30px'
      }}
    >
      {/* {(assistantDetails?.settings?.icon_id ||
        assistantDetails?.assistantIcon) && ( */}
      <Box paddingX={theme.spacing(2.5)}>
        {(assistantContext?.isPreview || assistantContext?.isStartChat) &&
        !assistantDetails?.assistantIcon &&
        !assistantDetails?.settings?.icon_id ? (
          <DefaultIcon
            style={{
              width: '70px',
              height: '70px',
              borderRadius: '10%',
              padding: '10px',
              backgroundColor: 'rgba(222, 224, 229, 0.70)'
            }}
          />
        ) : (
          <img
            src={
              assistantDetails?.assistantIcon ||
              icons?.filter(
                (icon) =>
                  icon.id === parseInt(assistantDetails?.settings?.icon_id)
              )?.[0]?.icon
            }
            width={'50px'}
            height={'50px'}
          ></img>
        )}
      </Box>
      <Box textAlign={'center'}>
        <Typography fontSize={'22px'} fontWeight={500}>
          {assistantDetails?.name}
        </Typography>
        <Typography fontSize={'17px'} lineHeight={'24px'}>
          {assistantDetails?.description ||
            assistantDetails?.settings?.description}
        </Typography>
      </Box>

      <Box width={'100%'}>
        <Grid
          container
          maxWidth={'md'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          width={'80%'}
          marginX={'auto'}
        >
          {starters?.map((starter, index) => (
            <Grid
              item
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              xs={12}
              md={4}
              paddingX={theme.spacing(1)}
              paddingTop={theme.spacing(1.5)}
              gap={1}
              key={index}
            >
              <Box>
                <QuestionCard
                  message={starter?.name || starter}
                  index={index}
                  handleQuestionCardClick={handleQuestionCardClick}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};

export default AssistantGpt;
