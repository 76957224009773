import { Switch as MuiSwitch, SwitchProps, styled } from '@mui/material';

const Switch = styled((props: SwitchProps) => (
  <MuiSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 30,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#84C633',
        opacity: 1,
        border: 0
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.colors.alpha.white[100]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        backgroundColor: theme.colors.alpha.white[100]
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: '6px solid #fff'
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: 'rgba(34, 51, 84, 1)'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      opacity: theme.palette.mode === 'light' ? 0.5 : 0.3,
      backgroundColor: theme.colors.primary.main
    },
    // change the background color of checked disabled switch

    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.5 : 0.3,
      border: 'none'
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    border: 'none',
    height: 12,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    }),
    padding: 0.2,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'rgba(34, 51, 84, 0.15)'
  }
}));

export default Switch;
