import SellIcon from '@mui/icons-material/Sell';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import AddTagIcon from 'src/assets/add.svg';
import StackedChips from 'src/components/StackedChips';
import Switch from 'src/components/Switch';
import { ONBOARDING_STEPS_TARGET } from 'src/components/UserOnboard/constants';
import MultiSelectTags from 'src/content/Documents/Tag/Multiselect';
import { TagType } from 'src/content/Documents/utils/utils';
import { AzureWebPubSubChatContext } from 'src/contexts/AzureWebPubSubChatContext';
import useLazyQuery from 'src/hooks/useLazyQuery';
import useMutation from 'src/hooks/useMutation';
import { updateStarterQuestion } from 'src/redux/slices/assistant';
import {
  updateChatModel,
  updateChatTags,
  updateStreamEventTimings
} from 'src/redux/slices/chat';
import { updateOnboarding } from 'src/redux/slices/onboarding';
import { RootState, useSelector } from 'src/redux/store';
import {
  useLazyGetFilesTagsQuery,
  usePostMessageMutation,
  useUpdateChatTagsMutation
} from 'src/services/api';
import { DataApiInputParams } from 'src/types/api';
import { SelectItem, StringKeys } from 'src/types/base';
import { ChatData } from 'src/types/chat';
import {
  ACCOUNT_TYPE,
  CHAT_SOURCE_TYPES,
  OnboardingStepTypes
} from 'src/types/enum';
import { ErrorContext } from 'src/utils/errorMappings';
import logger from 'src/utils/logger';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { CloseButton } from '../Conversation/ShareChat/styles';
import { QueryBoxPaper, TagsBadge, TagsButton } from '../styles';
import { IChatMessage } from '../types';
import { getHistoryStatus, getMessageTemplate } from '../utils';
import ChatInput from './ChatInput';
import ChatModels from './ChatModels';
import QueryActions from './QueryActions';
import { IS_ENCRYPTION_ENABLED } from 'src/config';
import ResourceInfo from './ResourceInfo';

type QueryBoxProps = {
  messageList: IChatMessage[];
  onQuerySend: (data: any, isError?: boolean) => void;
  onResponseReceive: (data: any, isError?: boolean) => void;
  isStreamingResponse: any;
  currentAssistantDetails?: StringKeys;
};

const QueryBox = ({
  messageList,
  onQuerySend,
  onResponseReceive,
  isStreamingResponse,
  currentAssistantDetails
}: QueryBoxProps) => {
  const { t, i18n } = useTranslation();
  const tagsContainer = useRef(null);
  const { language, userAIModel } = useSelector((state) => state.data);
  const { assistantObj, assistantContext, starterQuestion } = useSelector(
    (state) => state.assistant
  );
  const [textInput, setTextInput] = useState<string>('');
  const [isTagsDialogOpen, setIsTagsDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [updateTagsMutation] = useMutation({
    api: useUpdateChatTagsMutation,
    errorContext: ErrorContext.CHAT
  });
  const [stickToDocuments, setStickToDocuments] = useState<boolean>(false);
  const [disabledTags, setDisabledTags] = useState<boolean>(false);
  const [addedTags, setAddedTags] = useState<TagType>([]);
  const {
    selectedChat: chatId,
    chatContexts,
    chats
  } = useSelector((state: RootState) => state.chat);
  const {
    user: { accountType, isNonTrialUser }
  } = useSelector((state: RootState) => state.auth);
  const [isHistoryLinked, setIsHistoryLink] = useState<boolean>(true);
  const { connectionId, resetContext } = useContext(AzureWebPubSubChatContext);
  const [openedDocuments, setOpenedDocuments] = useState<number[]>([]);
  const [fetchTagsForChat, { data: chatTags }] = useLazyQuery<
    DataApiInputParams,
    StringKeys
  >({ api: useLazyGetFilesTagsQuery });
  const [sendQueryMessage, { isLoading: isSending }] = useMutation({
    api: usePostMessageMutation,
    errorContext: ErrorContext.CHAT,
    subContext:
      accountType === ACCOUNT_TYPE.ORGANIZATION_ADMINISTRATOR && ErrorContext.OA
  });
  const [chatData, setChatData] = useState<ChatData>(null);
  const [isWebSearch, setIsWebSearch] = useState<boolean>(false);
  const { isOnboarded } = useSelector((state: RootState) => state.data);
  const { stepsContext } = useSelector((state: RootState) => state.onboarding);

  const inputRef = useRef<HTMLInputElement>();

  const resourceNameTooltip = useMemo(() => {
    const documentContext = chatContexts[chatId]?.documentContext;
    if (chatData?.workspace_name || chatData?.r_name) {
      return chatData?.workspace_name
        ? `${chatData.workspace_name || ''} - ${chatData.r_name || ''}`
        : `${chatData.r_name || ''}`;
    }
    return chatData?.r_name || documentContext?.workspaceName
      ? `${documentContext?.workspaceName || ''} - ${
          documentContext?.name || ''
        }`
      : documentContext?.name || t(T.referenceDocumentDeleted);
  }, [chatData, i18n.language, chatContexts[chatId]?.documentContext]);

  const resourceName = useMemo(() => {
    const documentContext = chatContexts[chatId]?.documentContext;
    return chatData?.r_name
      ? `${chatData.r_name || ''}`
      : documentContext?.name
      ? `${documentContext?.name || ''}`
      : t(T.referenceDocumentDeleted);
  }, [chatData, i18n.language, chatContexts[chatId]?.documentContext]);

  const hasResourceName = useMemo(() => {
    return chatData?.rid || chatContexts[chatId]?.documentContext?.id;
  }, [chatData?.rid, chatContexts[chatId]?.documentContext?.id]);

  const hasAssistant = useMemo(() => {
    return assistantContext?.isAssistant;
  }, [assistantContext?.isAssistant]);

  const disableSendButton: boolean = useMemo(() => {
    const isDisabled =
      !!isSending || !textInput.trim().length || isStreamingResponse !== null;
    const resourceDeleted = chatData?.rid
      ? !!chatData?.workspace_name || !!chatData?.r_name || hasAssistant
      : true;
    const previewMandatoryField = assistantObj?.name && assistantObj?.aiModel;
    return (
      !resourceDeleted ||
      isDisabled ||
      (assistantContext?.isPreview && !previewMandatoryField)
    );
  }, [
    isSending,
    textInput,
    isStreamingResponse,
    chatData,
    hasAssistant,
    assistantObj
  ]);

  const isEmpowerGPTChat = useMemo(
    () =>
      chats?.find((c) => c?.id === chatId)?.chat_type ===
        CHAT_SOURCE_TYPES.EmpowerGPT ||
      chatContexts[0]?.chat_type === CHAT_SOURCE_TYPES.EmpowerGPT,
    [chatId, chats, chatContexts]
  );

  useEffect(() => {
    setOpenedDocuments(
      chatContexts[chatId]?.openedDocuments.map((item) => item.id)
    );
  }, [chatContexts[chatId]?.openedDocuments]);

  const tagsList = useMemo(
    () => chatContexts[chatId]?.tags || [],
    [chatContexts, chatId]
  );

  const selectedChatData = useMemo(
    () => (chatId ? chats?.find((item) => item?.id === chatId) : null),
    [chatId, chats]
  );

  useEffect(() => {
    let chatModel =
      selectedChatData && selectedChatData?.model_id
        ? {
            id: selectedChatData.model_id,
            name: selectedChatData.model_name,
            display_name: selectedChatData.model_display_name
          }
        : chatContexts[chatId]?.chatModel
        ? chatContexts[chatId]?.chatModel
        : userAIModel;

    setChatData(selectedChatData || null);
    dispatch(
      updateChatModel({
        chatId: chatId,
        chatModel: chatModel
      })
    );
    inputRef?.current?.focus();
    return () => {
      setTextInput('');
    };
  }, [chatId, selectedChatData?.web_search_enabled]);

  useEffect(() => {
    inputRef?.current.focus();
  }, [isEmpowerGPTChat]);

  useEffect(() => {
    if (chatId !== null && chatId !== undefined) {
      setIsHistoryLink(getHistoryStatus(chats, chatId));
      fetchTagsForChat({
        params: {
          params: {
            perm: 'query-files-folders',
            p: 0,
            ps: 100
          }
        }
      });
    }
  }, [chatId]);

  useEffect(() => {
    if (chatContexts[chatId]?.tags && chatTags?.data)
      dispatch(
        updateChatTags({
          chatId,
          tags: (chatContexts[chatId]?.tags || []).filter((tag) =>
            (chatTags?.data || [])?.find((item) => item.id === tag.id)
          )
        })
      );
  }, [chatTags, chatId]);

  useEffect(() => {
    if (!openedDocuments?.length) {
      setDisabledTags(false);
      setStickToDocuments(false);
    }
  }, [openedDocuments?.length]);

  useEffect(() => {
    const handleSetValue = (event) => {
      setTextInput(t(event.detail));
    };

    window.addEventListener('handle-message-input', handleSetValue);

    return () => {
      window.removeEventListener('handle-message-input', handleSetValue);
    };
  }, []);

  useEffect(() => {
    if (starterQuestion?.length > 0) {
      onSend();
    }
  }, [starterQuestion]);

  const onClickSend = async () => {
    let queryText = textInput;
    if (!textInput && !starterQuestion) {
      return;
    }
    let chat_history = [];
    if (hasAssistant && assistantContext?.isPreview) {
      // TODO: make the object according to the format
      for (let i = 0; i < messageList.length; i += 2) {
        chat_history.push({
          q: { message: messageList?.[i]?.message || '' },
          r: { message: messageList?.[i + 1]?.message || '' },
          encrypted: IS_ENCRYPTION_ENABLED
        });
      }
    }
    const apiParams: DataApiInputParams = {
      params: {
        msg: starterQuestion || textInput,
        chat_id: chatId || '',
        ...(chatContexts[chatId]?.documentContext?.id &&
          !assistantObj?.isWebSearch && {
            rid: chatContexts[chatId]?.documentContext?.id
          }),
        wps_conn_id: connectionId,
        chat_type:
          hasAssistant &&
          (assistantObj?.isWebSearch || assistantObj?.worldKnowledge)
            ? CHAT_SOURCE_TYPES.ChatGPT
            : chatContexts[chatId].chat_type,
        ...(stickToDocuments && {
          document_context: openedDocuments.toString()
        }),
        ...(!isHistoryLinked && {
          disable_history: !isHistoryLinked
        }),
        model_id: chatContexts[chatId].chatModel?.id,
        web_search_enabled: isWebSearch,
        ...(hasAssistant &&
          !assistantContext?.isPreview && {
            assistant_id:
              chatContexts[chatId]?.documentContext?.id ||
              currentAssistantDetails?.id
          }),
        ...(hasAssistant &&
          assistantContext?.isPreview && {
            preview: JSON.stringify({
              ...assistantObj,
              model_id: assistantObj?.aiModel?.id,
              rid_list: assistantObj?.fileList,
              question: textInput,
              chat_history: chat_history
            })
          })
      }
    };
    if (tagsList.length) {
      apiParams.params.tag_id = tagsList
        .map((tag) => (typeof tag === 'string' ? tag : tag?.id))
        .toString();
    }
    setTextInput('');
    const querySendTime = new Date();
    logger.log('%c Query send time', 'color: orange;', querySendTime);
    await sendQueryMessage({
      params: apiParams,
      fallbackMsg: T.errorGeneratingResponse,
      successCallback: (res: any) => {
        const queryAcknowledgeTime = new Date();
        dispatch(
          updateStreamEventTimings({
            chatId,
            streamEventTimings: {
              querySendTime: querySendTime.getTime(),
              queryAcknowledgeTime: queryAcknowledgeTime.getTime()
            }
          })
        );
        dispatch(updateStarterQuestion(''));
        logger.log(
          '%c Query acknowledge time',
          'color: orange;',
          queryAcknowledgeTime
        );
        logger.log(
          '%c Query api time duration',
          'color: red;',
          (queryAcknowledgeTime.getTime() - querySendTime.getTime()) / 1000,
          'seconds'
        );
        // update chat tags-context in case of new chat...
        if (chatId === 0 && tagsList.length) {
          dispatch(updateChatTags({ chatId: chatId, tags: tagsList }));
        }
        onResponseReceive(res.data);
      },
      errorCallback: (_: any) => {
        logger.log('%c Query error', 'color: red;', new Date(), _);
        onResponseReceive({}, true);
        dispatch(updateStarterQuestion(''));
        setTextInput(queryText);
        if (!isOnboarded && stepsContext === OnboardingStepTypes.CHAT) {
          dispatch(
            updateOnboarding({ stepsContext: OnboardingStepTypes.DOCUMENT })
          );
        }
      }
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (disableSendButton) return;
    onSend();
  };

  const onSend = () => {
    onClickSend();
    resetContext();
    onQuerySend(
      getMessageTemplate(
        starterQuestion || textInput,
        chatId,
        chatContexts[chatId].chatModel
      )
    );
  };

  const onRegenerateResponse = () => {
    onClickSend();
  };

  const onTagAdded = (selected: TagType) => {
    setAddedTags(selected);
  };

  const onClickStickToDocuments = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDisabledTags(event.target.checked);
    setStickToDocuments(event.target.checked);
  };

  const toggleHistoryContext = () => {
    setIsHistoryLink((prevState) => !prevState);
  };

  const toggleWebSearch = () => {
    setIsWebSearch((prevState) => !prevState);
  };

  const onTagRemoved = (removed: number) => {
    const updatedList = tagsList.filter((item) => item.id !== removed);
    dispatch(
      updateChatTags({
        chatId,
        tags: updatedList
      })
    );
    postTags(updatedList);
  };

  const tagsToDisplay = useMemo(() => {
    return tagsList.map((item) => ({
      id: item.id,
      text: item.name
    }));
  }, [tagsList]);

  const postTags = async (tagsList) => {
    if (chatId) {
      const tags = tagsList.map((item) => item.id);
      await updateTagsMutation({
        params: { params: { tags_list: tags.toString(), chat_id: chatId } }
      });
    }
  };

  const openTagsDialog = useCallback(() => setIsTagsDialogOpen(true), []);

  const closeTagsDialog = useCallback(() => {
    postTags(tagsList);
    dispatch(updateChatTags({ chatId, tags: addedTags }));
    setAddedTags([]);
    setIsTagsDialogOpen(false);
  }, [tagsList, chatId, addedTags]);

  const onTagCancel = useCallback(() => {
    setIsTagsDialogOpen(false);
    setAddedTags([]);
  }, []);

  useEffect(() => {
    setIsWebSearch(
      chatData?.web_search_enabled || assistantObj?.isWebSearch || false
    );
  }, [chatData?.web_search_enabled, assistantObj?.isWebSearch]);

  return (
    <>
      {/* {isError && <RegenerateResponse onClick={onRegenerateResponse} />} */}
      <Grid
        container
        width={'100%'}
        display="flex"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Grid item flex={'1'} xs>
          <QueryBoxPaper id={ONBOARDING_STEPS_TARGET.CHAT_QUERYBOX}>
            <ChatInput
              textInput={textInput}
              setTextInput={setTextInput}
              handleKeyPress={handleKeyPress}
              isEmpowerGPTChat={isEmpowerGPTChat}
              inputRef={inputRef}
            />
            <Box display={'flex'} justifyContent={'flex-end'}>
              {isEmpowerGPTChat && !assistantObj?.isWebSearch ? (
                <Box
                  ref={tagsContainer}
                  flex={1}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'12px'}
                  height={'25px'}
                >
                  {isNonTrialUser && !hasResourceName && !hasAssistant && (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      gap={'12px'}
                      className="tags-length-issue"
                    >
                      <Tooltip title={t(T.applyTags)}>
                        <TagsBadge
                          badgeContent={tagsList?.length}
                          color="primary"
                          overlap="circular"
                        >
                          <TagsButton
                            disabled={!!hasResourceName}
                            onClick={openTagsDialog}
                          >
                            <img
                              src={AddTagIcon}
                              style={{
                                width: '14px',
                                height: '14px'
                              }}
                            />
                          </TagsButton>
                        </TagsBadge>
                      </Tooltip>
                      {tagsToDisplay.length ? (
                        <StackedChips
                          disabled={disabledTags || false}
                          data={tagsToDisplay || []}
                          icon={<SellIcon />}
                          maxNumber={0}
                          dialogTitle="Tags"
                          allowRemove={true}
                          onTagRemove={onTagRemoved}
                          color="default"
                          size="small"
                          parentWidth={tagsContainer}
                          staticOffset={300}
                          showMore={false}
                        />
                      ) : null}

                      <Divider
                        orientation="vertical"
                        sx={{
                          height: '30px'
                        }}
                      />
                    </Box>
                  )}
                  {hasResourceName && !hasAssistant && (
                    <ResourceInfo
                      resourceName={resourceName}
                      resourceNameTooltip={resourceNameTooltip}
                      isError={resourceName === T.referenceDocumentDeleted}
                    />
                  )}
                  {(!hasAssistant || assistantObj?.aiModel) && (
                    <ChatModels
                      isDisabled={!!messageList?.length || hasAssistant}
                    />
                  )}
                  {(assistantObj?.permission?.resources?.length > 0 ||
                    assistantObj?.fileList?.length > 0) && (
                    <>
                      <Divider
                        orientation="vertical"
                        sx={{
                          height: '25px'
                        }}
                      />
                      <Typography color={'#8E98B7'} variant="body1">
                        {T.enterpriseKnowledge}
                      </Typography>
                    </>
                  )}
                  {chatId > 0 && isEmpowerGPTChat && (
                    <QueryActions
                      messageList={messageList}
                      isHistoryLinked={isHistoryLinked}
                      onChangeHistory={toggleHistoryContext}
                      stickToDocuments={stickToDocuments}
                      onClickStickToDocuments={onClickStickToDocuments}
                    />
                  )}
                </Box>
              ) : (
                <Box
                  flex={1}
                  display={'flex'}
                  alignItems={'center'}
                  sx={{ ml: 1 }}
                  gap={'12px'}
                >
                  {(!hasAssistant || assistantObj?.aiModel) && (
                    <>
                      <ChatModels
                        isDisabled={!!messageList?.length || hasAssistant}
                      />
                      <Divider
                        orientation="vertical"
                        sx={{
                          height: '25px'
                        }}
                      />
                    </>
                  )}
                  {hasAssistant ? (
                    assistantObj?.isWebSearch && (
                      <Typography color="#8E98B7" variant="body1">
                        {T.webSearch}
                      </Typography>
                    )
                  ) : (
                    <Box display="flex" flexDirection="row">
                      <Tooltip
                        title={t(T.webSearchDescription)}
                        placement="top"
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          mr={1}
                        >
                          <Switch
                            checked={isWebSearch}
                            onChange={toggleWebSearch}
                            disabled={isSending}
                          />
                        </Box>
                      </Tooltip>
                      <Typography color={isWebSearch ? '#222F59' : 'gray'}>
                        {t(T.webSearch)}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
              <Box justifySelf={'flex-end'} alignSelf={'end'}>
                <IconButton
                  id={ONBOARDING_STEPS_TARGET.CHAT_SEND_MSG_BUTTON}
                  disabled={disableSendButton}
                  onClick={onSend}
                  sx={{ height: '24px', width: '24px' }}
                >
                  <SendTwoToneIcon />
                </IconButton>
              </Box>
            </Box>
          </QueryBoxPaper>
        </Grid>
      </Grid>
      <Dialog
        open={isTagsDialogOpen}
        onClose={closeTagsDialog}
        fullWidth
        maxWidth="sm"
      >
        <CloseButton onClick={onTagCancel} />
        <DialogTitle>{t(T.tags)}</DialogTitle>
        <DialogContent sx={{ p: 2, mb: 5 }}>
          <Box sx={{ my: (theme) => theme.spacing(2) }}>
            <MultiSelectTags
              onTagChange={onTagAdded}
              tagsList={chatTags?.data || []}
              defaultValue={(tagsList || []) as SelectItem[]}
              freeSolo={false}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={onTagCancel} variant="outlined" size="medium">
            {t(T.cancel)}
          </Button>
          <Button
            onClick={closeTagsDialog}
            variant="contained"
            color="secondary"
            size="medium"
          >
            {t(T.confirm)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QueryBox;
