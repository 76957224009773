import { TRANSLATION_CONSTANTS as T } from './translations';
export default [
  {
    id: 1,
    title: T.promptTitle1,
    prompt: T.promptText1
  },
  {
    id: 2,
    title: T.promptTitle2,
    prompt: T.promptText2
  },
  {
    id: 3,
    title: T.promptTitle3,
    prompt: T.promptText3
  },
  {
    id: 4,
    title: T.promptTitle4,
    prompt: T.promptText4
  },
  {
    id: 5,
    title: T.promptTitle5,
    prompt: T.promptText5
  },
  {
    id: 6,
    title: T.promptTitle6,
    prompt: T.promptText6
  },
  {
    id: 7,
    title: T.promptTitle7,
    prompt: T.promptText7
  },
  {
    id: 8,
    title: T.promptTitle8,
    prompt: T.promptText8
  },
  {
    id: 9,
    title: T.promptTitle9,
    prompt: T.promptText9
  },
  {
    id: 10,
    title: T.promptTitle10,
    prompt: T.promptText10
  }
];
