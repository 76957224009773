import { Grid } from '@mui/material';
import CollectionCard from './AssistantCard';

const GridView = ({
  rows,
  icons,
  handleShareResourceClick,
  handleStartChatClick,
  setDeletedRowId,
  setDeleteOpen,
  handleCreateModalOpen,
  handleFetchAssistants
}) => {
  const handleStartChat = (row) => {
    handleStartChatClick(row);
  };
  return (
    <Grid
      container
      width={'80%'}
      sx={{
        height: `calc(100% - 64px)`,
        marginTop: '30px',
        marginX: 'auto'
      }}
      display={'flex'}
      flexWrap={'nowrap'}
      flexDirection={'column'}
      direction={'column'}
    >
      <Grid container pb={'12px'} rowSpacing={2} columnSpacing={2}>
        {rows.map((row: any, index: number) => {
          return (
            <Grid item key={index} xs={12} sm={12} md={6} lg={6} xl={6}>
              <CollectionCard
                row={row}
                icons={icons}
                handleShareResourceClick={handleShareResourceClick}
                handleStartChatClick={handleStartChat}
                setDeletedRowId={setDeletedRowId}
                setDeleteOpen={setDeleteOpen}
                handleCreateModalOpen={handleCreateModalOpen}
                handleFetchAssistants={handleFetchAssistants}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default GridView;
