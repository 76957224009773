import { getEncryptParams } from 'src/keycloak';
import { DataApiInputParams } from 'src/types/api';
import {
  Builder,
  appendFormData,
  decryptResponse,
  getHeaders,
  getQueryString,
  serializeErrorResponse
} from './utils';
import { IS_ENCRYPTION_ENABLED } from 'src/config';
import { getPayload } from 'src/utils/encryption';

export default (builder: Builder) => {
  return {
    getAssistantHistory: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const decryptKey = getEncryptParams()?.k;
        const url = `mini-experts/assistants?${getQueryString(arg.params)}`;
        const result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    getAssistant: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const decryptKey = getEncryptParams()?.k;
        const url = `mini-experts/assistants?${getQueryString(arg.params)}`;
        const result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    getAllAssistants: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const decryptKey = getEncryptParams()?.k;
        let tempParams: any = {
          ...arg.params,
          fetch_all:
            arg.params?.fetch_all === undefined ? true : arg.params?.fetch_all
        };
        const url = `mini-experts/assistant_details_service?${getQueryString(
          tempParams
        )}`;
        const result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['Assistant']
    }),
    getAssistantDetail: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const decryptKey = getEncryptParams()?.k;
        let tempParams: any = {
          ...arg.params,
          fetch_all:
            arg.params?.fetch_all === undefined ? true : arg.params?.fetch_all
        };
        const url = `mini-experts/assistant_details_service?${getQueryString(
          tempParams
        )}`;
        const result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    createAssistant: builder.mutation({
      async queryFn(arg: DataApiInputParams, _, __, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;

        let formData = appendFormData(arg.params);
        let url = `mini-experts/assistants`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Assistant']
    }),
    deleteAssistant: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `mini-experts/assistants`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'DELETE',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Assistant', 'Favourites']
    }),
    updateAssistant: builder.mutation({
      async queryFn(arg: DataApiInputParams, _, __, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;

        let formData = appendFormData(arg.params);

        let url = `mini-experts/assistants`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'PATCH',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Assistant', 'Favourites']
    }),
    getAssistantContentAccessRights: builder.query({
      async queryFn(arg: DataApiInputParams, _, __, fetchWithBQ) {
        const queryString = getQueryString(arg.params);
        let url = `mini-experts/assistant-perms-details${
          queryString.length ? '?' + queryString : ''
        }`;
        let decryptKey = getEncryptParams()?.k;

        if (IS_ENCRYPTION_ENABLED) {
          url = `${url}${queryString.length ? '&' : '?'}payload=${btoa(
            getPayload('', getEncryptParams())
          )}`;
        }

        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });

        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val.data }
          : serializeErrorResponse(response);
      }
    }),
    updateAssistantResource: builder.mutation({
      async queryFn({ params }, { getState }, extraOptions, baseQuery) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(params);
        let result: any = await baseQuery({
          url: `mini-experts/assistant-perms-details`,
          method: 'POST',
          body: formData,
          ...getHeaders(`mini-experts/assistant-perms-details`, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Assistant']
    })
  };
};
