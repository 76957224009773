import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from 'src/types/header';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

type Props = {
  handleCreateAssistant?: () => void;
  showCreateButton?: boolean;
};

export const ActionButtons = ({
  handleCreateAssistant,
  showCreateButton
}: Props): IActionButtonProps[] => {
  const { t } = useTranslation();

  return [
    {
      color: 'primary',
      visible: showCreateButton,
      variant: 'outlined',
      icon: Add,
      tooltipMsg: t(T.createAssistant),
      disabled: false,
      popover: false,
      onClick: handleCreateAssistant,
      text: t(T.create)
    }
  ];
};
