import { Box, Link, TextField, Typography } from '@mui/material';
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from 'src/assets/add.svg';
import Loader from 'src/components/Loader';
import DialogLayout from 'src/components/MiniExperts/CommonDialogLayout';
import useLazyQuery from 'src/hooks/useLazyQuery';
import useMutation from 'src/hooks/useMutation';
import {
  useCreateQuestionnaireMutation,
  useEditQuestionnaireMutation,
  useLazyGetQuestionnaireQuery
} from 'src/services/api';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { v4 as uuidV4 } from 'uuid';
import { StyledLinkButton } from '../styles';
import QuestionRow from './QuestionRow';

interface CreateQuestionnaireModalProps {
  isOpen: boolean;
  onClose: () => void;
  cloneID?: number;
  questionnaireToEdit?: { id: number; name: string };
}

export const CreateQuestionnaireModal: FC<CreateQuestionnaireModalProps> = ({
  isOpen,
  onClose,
  questionnaireToEdit,
  cloneID
}) => {
  const { t } = useTranslation();
  const questionsContainerRef = useRef<HTMLDivElement>(null);
  const [title, setTitle] = useState<string>(questionnaireToEdit?.name || '');
  const [isQuestionnaireAssociated, setIsQuestionnaireAssociated] =
    useState(false);
  const [questions, setQuestions] = useState<any[]>([
    { id: uuidV4(), question: '' }
  ]);
  const [deletedQuestionIds, setDeletedQuestionIds] = useState<number[]>([]);

  const [createQuestionnaire] = useMutation({
    api: useCreateQuestionnaireMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const [fetchQuestionnaire, { data, isLoading }] = useLazyQuery({
    api: useLazyGetQuestionnaireQuery,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const [editQuestionnaire] = useMutation({
    api: useEditQuestionnaireMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  useEffect(() => {
    if (cloneID || questionnaireToEdit?.id) {
      fetchQuestionnaire({
        params: {
          params: {
            id: cloneID || questionnaireToEdit?.id
          }
        },
        successCallback: (data) => {
          setIsQuestionnaireAssociated(
            cloneID ? false : data.is_questionnaire_associated
          );
          const questions = data.data.map(({ id, question }) => ({
            id: !!questionnaireToEdit ? id : uuidV4(),
            question
          }));
          setQuestions(questions);
        }
      });
    }
  }, [cloneID, questionnaireToEdit]);

  const hasEmptyQuestion = useMemo(
    () => questions.some((q) => q.question.trim() === ''),
    [questions]
  );

  const findQuestion = useCallback((questions: any[], id: number) => {
    const index = questions.findIndex((q) => q.id === id);
    return {
      question: questions[index],
      index
    };
  }, []);

  const moveQuestion = useCallback((id: number, atIndex: number) => {
    setQuestions((questions) => {
      const { question, index } = findQuestion(questions, id);
      return (questions as any)
        .toSpliced(index, 1)
        .toSpliced(atIndex, 0, question);
    });
  }, []);

  const moveQuestionToId = useCallback((id: number, moveToId: number) => {
    setQuestions((questions) => {
      const { index: atIndex } = findQuestion(questions, moveToId);
      const { question, index } = findQuestion(questions, id);
      return (questions as any)
        .toSpliced(index, 1)
        .toSpliced(atIndex, 0, question);
    });
  }, []);

  const handleTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }, []);

  const handleQuestionChange = useCallback((id: number, question: string) => {
    setQuestions((questions) => {
      const {
        index,
        question: { isNewQuestion }
      } = findQuestion(questions, id);

      return (questions as any).toSpliced(index, 1, {
        id,
        question,
        isNewQuestion
      });
    });
  }, []);

  const addNewQuestion = useCallback(() => {
    setQuestions((q) => [
      ...q,
      { id: uuidV4(), question: '', isNewQuestion: true }
    ]);
    // TODO
    setTimeout(() => {
      (questionsContainerRef.current?.lastChild as HTMLElement)
        .getElementsByTagName('textarea')[0]
        ?.focus();
    });
  }, []);

  const removeQuestion = useCallback((id: number) => {
    let removedQuestion: any;

    setQuestions((questions) => {
      const { index, question } = findQuestion(questions, id);
      removedQuestion = question;
      return (questions as any).toSpliced(index, 1);
    });

    if (questionnaireToEdit && !removedQuestion.isNewQuestion) {
      setDeletedQuestionIds((prev) => [...prev, id]);
    }
  }, []);

  const handleUpdateQuestionnaire = useCallback(() => {
    const newQuestions = [],
      editedQuestions = [];
    questions.forEach(({ id, question, isNewQuestion }) => {
      if (isNewQuestion) {
        if (question !== '') {
          newQuestions.push(question);
        }
      } else {
        editedQuestions.push({ id, question });
      }
    });

    const optionalParams = {};
    if (newQuestions.length) {
      optionalParams['add_list'] = JSON.stringify(newQuestions);
    }
    if (deletedQuestionIds.length) {
      optionalParams['delete_list'] = deletedQuestionIds;
    }

    editQuestionnaire({
      params: {
        params: {
          questionnaire_id: questionnaireToEdit?.id,
          edit_list: JSON.stringify(editedQuestions),
          ...optionalParams
        }
      },
      successMsg: t(T.questionnaireUpdatedSuccess),
      fallbackMsg: t(T.questionnaireUpdateFailure),
      successCallback: onClose
    });
  }, [questions, deletedQuestionIds]);

  const handleAddQuestionnaire = useCallback(() => {
    const question_list = questions.map((q) => q.question).filter((q) => q);
    createQuestionnaire({
      params: {
        params: {
          title,
          question_list: JSON.stringify(question_list)
        }
      },
      successMsg: T.questionnaireCreationSuccess,
      successCallback: onClose
    });
  }, [title, questions]);

  const handleConfirmation = useCallback(() => {
    if (questionnaireToEdit) {
      handleUpdateQuestionnaire();
    } else {
      handleAddQuestionnaire();
    }
  }, [questionnaireToEdit, handleAddQuestionnaire, handleUpdateQuestionnaire]);

  const focusNode = useCallback((node) => {
    node?.focus();
  }, []);

  return (
    <DialogLayout
      isOpen={isOpen}
      onClose={onClose}
      dialogTitle={questionnaireToEdit ? T.questionnaire : T.addQuestionnaire}
      confirmationButtonText={questionnaireToEdit ? T.update : T.save}
      disableButton={
        isQuestionnaireAssociated || hasEmptyQuestion || !title.trim()
      }
      handleConfirmation={handleConfirmation}
      sxDialog={{
        '& .MuiDialog-paper.MuiPaper-root': { height: '75%', maxWidth: '720px' }
      }}
      maxWidth={false}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Box display="flex" flexDirection="column" maxHeight="100%">
          {isQuestionnaireAssociated && (
            <Typography color={'error'} marginBottom={2}>
              {t(T.questionnaireAssociationMsg)}
            </Typography>
          )}
          <TextField
            value={title}
            onChange={handleTitleChange}
            placeholder={t(T.questionnaireTitlePlaceholder)}
            disabled={!!questionnaireToEdit}
            fullWidth
            sx={{
              '& .MuiInputBase-input': {
                padding: 1
              }
            }}
            inputRef={focusNode}
          />

          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            marginTop="24px"
            overflow="auto"
            maxHeight="fit-content"
            ref={questionsContainerRef}
          >
            <DndProvider backend={HTML5Backend}>
              {questions.map(({ id, question }, index) => (
                <QuestionRow
                  key={id}
                  id={id}
                  index={index}
                  question={question}
                  handleQuestionChange={handleQuestionChange}
                  moveQuestion={moveQuestion}
                  removeQuestion={removeQuestion}
                  disableActionButtons={
                    isQuestionnaireAssociated || questions.length === 1
                  }
                  isReorderingVisible={!questionnaireToEdit}
                  moveQuestionToId={moveQuestionToId}
                />
              ))}
            </DndProvider>
          </Box>
          <StyledLinkButton
            onClick={addNewQuestion}
            disabled={
              isQuestionnaireAssociated ||
              hasEmptyQuestion ||
              questions.length >= 300
            }
          >
            <AddIcon width="16px" />
            <Link fontSize={'16px'} fontWeight={500} underline="none">
              {t(T.addQuestion)}
            </Link>
          </StyledLinkButton>
        </Box>
      )}
    </DialogLayout>
  );
};

export default CreateQuestionnaireModal;
