import { useEffect, useState } from 'react';
import {
  useTheme,
  IconButton,
  Box,
  Divider,
  Tooltip,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePatchMessageMutation } from 'src/services/api';
import { RootState, useSelector } from 'src/redux/store';
import { DataApiInputParams } from 'src/types/api';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useDispatch } from 'react-redux';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { findLast } from 'lodash';
import { IChatMessage, MESSAGE_TYPE } from '../types';
import useMutation from 'src/hooks/useMutation';
import { ErrorContext } from 'src/utils/errorMappings';
import Switch from 'src/components/Switch';

type IQueryActions = {
  messageList: IChatMessage[];
  stickToDocuments: boolean;
  isHistoryLinked: boolean;
  onChangeHistory: () => void;
  onClickStickToDocuments: (event: any) => void;
};

const QueryActions = ({
  messageList,
  stickToDocuments,
  isHistoryLinked,
  onChangeHistory,
  onClickStickToDocuments
}: IQueryActions) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [openedDocuments, setOpenedDocuments] = useState<number[]>([]);
  const { selectedChat: chatId, chatContexts } = useSelector(
    (state: RootState) => state.chat
  );

  useEffect(() => {
    setOpenedDocuments(
      chatContexts[chatId]?.openedDocuments.map((item) => item.id)
    );
  }, [chatContexts[chatId]?.openedDocuments]);

  const [addHistoryContext, { isLoading: isUpdatingContext }] = useMutation({
    api: usePatchMessageMutation,
    errorContext: ErrorContext.CHAT
  });

  const toggleHistoryContext = () => {
    onSubmit();
    onChangeHistory();
  };

  const onSubmit = async () => {
    if (!isUpdatingContext && messageList && messageList.length > 2) {
      const lastMessageId = findLast(
        messageList,
        (item) => item.type === MESSAGE_TYPE.Q
      );
      if (!lastMessageId) {
        return;
      }
      const apiParams: DataApiInputParams = {
        params: {
          id: lastMessageId.id,
          history_context: !isHistoryLinked,
          chat_id: chatId
        }
      };
      await addHistoryContext({
        params: apiParams,
        fallbackMsg: T.errorUpdatingChatContext
      });
    }
  };

  return (
    <Box display={'flex'} padding={0}>
      <Divider orientation="vertical" sx={{ marginRight: '12px' }} />
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        marginRight={'12px'}
      >
        <Tooltip
          title={
            openedDocuments?.length ? t(T.stickToDocs) : t(T.stickDocMessage)
          }
        >
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            mr={1}
          >
            <Switch
              checked={!!openedDocuments?.length && stickToDocuments}
              onChange={onClickStickToDocuments}
              disabled={!openedDocuments?.length}
            />
          </Box>
        </Tooltip>
        <Typography color={!!openedDocuments?.length ? '#222F59' : 'gray'}>
          {t(T.stickToDoc)}
        </Typography>
      </Box>
      <Divider orientation="vertical" />
      <IconButton
        onClick={toggleHistoryContext}
        size="small"
        sx={{
          marginLeft: 1
        }}
      >
        {isHistoryLinked ? (
          <Tooltip title={t(T.excludeChatHisory)}>
            <LinkIcon
              sx={{
                color: theme.colors.primary.main
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title={t(T.includeChatHistory)}>
            <LinkOffIcon
              sx={{
                color: theme.colors.primary.main
              }}
            />
          </Tooltip>
        )}
      </IconButton>
    </Box>
  );
};

export default QueryActions;
