import { Box, styled, Tab, Typography } from '@mui/material';
import { AddOutlined, DeleteOutlined } from '@mui/icons-material';

export const MainContentWrapper = styled(Box)(`
    flex-grow: 1;
    height: 100%;
    overflow:auto;
`);

export const CreateAssistantFormContainer = styled(Box)(`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    rowGap: 20px;
`);

export const StyledBox = styled(Box)({
  padding: '12px',
  backgroundColor: '#CAD9F233',
  textAlign: 'center',
  borderRadius: '10px',
  cursor: 'pointer'
});

export const StyledFileListBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  maxHeight: '150px',
  overflow: 'hidden',
  overflowY: 'auto',
  gap: '10px 54px'
});

export const IconsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '30px',
  marginTop: '10px',
  padding: '10px'
});

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const fileIconStyle = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '250px',
  alignItems: 'center',
  height: '40px',
  borderRadius: '3%',
  '&:hover': {
    backgroundColor: '#f1f5f9'
  }
});

export const CustomFileDetailsBox = styled(fileIconStyle)({
  gap: '8px',
  justifyContent: 'flex-start',
  padding: '30px 12px',
  cursor: 'default',
  border: '1px solid #cad9f2'
});

export const CustomAddFileBox = styled(fileIconStyle)({
  justifyContent: 'center',
  cursor: 'pointer',
  padding: '30px 50px',
  border: '1px dashed #cad9f2'
});

export const CustomDeleteIcon = styled(DeleteOutlined)({
  border: '1px solid #cad9f2',
  borderRadius: '7%',
  color: 'red',
  textAlign: 'center',
  padding: '15px',
  cursor: 'pointer',
  height: '53px',
  width: '53px'
});

export const CustomAddIcon = styled(AddOutlined)({
  border: '1px solid #cad9f2',
  borderRadius: '7%',
  textAlign: 'center',
  padding: '12px',
  cursor: 'pointer',
  height: '53px',
  width: '53px'
});

export const FileDisclaimerText = styled(Typography)({
  letterSpacing: '0.5px',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '18px',
  paddingLeft: '8px',
  color: '#222f59b3',
  marginLeft: '35px',
  marginBottom: '10px'
});

export const AssistantStyledTab = styled(Tab)(() => ({
  fontWeight: '400 !important',
  minWidth: 'max-content !important',
  margin: '0 2px',
  paddingLeft: '14px !important',
  paddingRight: '14px !important',
  ['&.Mui-selected']: {
    borderRadius: '7.14px !important',
    boxShadow:
      '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'
  }
}));

export const IconImage = styled('img')`
  width: 60px;
  height: 60px;
  align-self: center;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;
