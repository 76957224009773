import { Box, Divider, Grid, Paper, Tabs, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { ReactComponent as SortFilterIcon } from 'src/assets/assistantIcons/sort-filter-icon.svg';
import HeaderButton from 'src/components/PageHeader/HeaderButton';
import { StringKeys } from 'src/types/base';
import { FilterComponents } from 'src/types/custom_component';
import { IActionButtonProps } from 'src/types/header';
import HeaderFilter from '../../../components/HeaderFilterwithPopOver';
import { AssistantStyledTab } from '../utils/styles';
import { ITab } from '../utils/types';

type FilterProps = {
  onSearch: (selectedFields: { [z: string]: any }) => any;
  onReset: (initialObject: any) => void;
  forceReset?: boolean;
  elementsList: FilterComponents[];
  Button?: any;
  showFilters?: boolean;
  disableFilters?: boolean;
  filtersValue?: StringKeys;
  setFiltersValue?: React.Dispatch<React.SetStateAction<StringKeys>>;
  hideFilterButton?: boolean;
  isDropDown?: boolean;
};

type Props = {
  title: string;
  showTabs?: boolean;
  tabs?: ITab[];
  setSelectedTab?: (tab: string) => void;
  selectedTab?: string;
  actionButtons?: IActionButtonProps[];
  filterProps?: FilterProps;
};

const PageHeader = ({
  title,
  showTabs = false,
  tabs,
  selectedTab,
  setSelectedTab = () => {},
  actionButtons,
  filterProps
}: Props) => {
  const renderHeaderButton = useCallback(
    (actionButton: IActionButtonProps, index: number) => {
      if (!actionButton || !actionButton.visible) {
        return null;
      }
      return (
        <React.Fragment key={index}>
          <Grid item display={'flex'}>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item>
            <HeaderButton
              isButtonDisabled={actionButton.disabled}
              onClick={actionButton.onClick}
              tooltipMessage={actionButton.tooltipMsg}
              isPopover={actionButton.popover}
              buttonProps={{
                color: actionButton.color,
                startIcon: <actionButton.icon />,
                variant: actionButton.variant
              }}
              popoverElements={actionButton.popoverElements}
              btnText={actionButton.text}
              elemId={actionButton.elementId}
            />
          </Grid>
        </React.Fragment>
      );
    },
    []
  );

  return (
    <Paper elevation={0}>
      <Grid
        container
        sx={{ height: '60px' }}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item>
          <Box display={'flex'} alignItems={'center'} gap={'16px'}>
            <Typography fontSize={'32px'} fontWeight={500}>
              {title}
            </Typography>
            {showTabs && (
              <TabsPanel
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                tabs={tabs}
              />
            )}
          </Box>
        </Grid>
        <Grid item>
          <Grid container spacing={1.5}>
            {filterProps && (
              <HeaderFilter
                {...filterProps}
                componentsValue={filterProps.filtersValue}
                setComponentValue={filterProps.setFiltersValue}
                filterIcon={<SortFilterIcon />}
              />
            )}

            {actionButtons.map(renderHeaderButton)}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PageHeader;

const TabsPanel = ({ setSelectedTab, selectedTab, tabs }) => {
  const handleTabChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setSelectedTab(newValue);
  };

  return (
    <Tabs
      onChange={handleTabChange}
      aria-label="assistant-tab-selection"
      value={selectedTab}
    >
      {tabs?.map((tab: ITab, index: number) => (
        <AssistantStyledTab key={index} label={tab.label} value={tab.value} />
      ))}
    </Tabs>
  );
};
