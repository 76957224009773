import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataLibraryExplorer from 'src/components/DataLibraryExplorer';
import { StringKeys } from 'src/types/base';
import { DOCUMENT_STATUS, RESOURCE_TYPE } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { MAX_FILES_ATTACHED } from '../utils/utils';

interface ImportFilesProps {
  open: boolean;
  handleClose: () => void;
  selectedDocs: StringKeys[];
  setSelectedDocs: (docs: StringKeys[]) => void;
  handleImportFiles: () => void;
}

const ImportFiles = ({
  open,
  handleClose,
  selectedDocs,
  setSelectedDocs,
  handleImportFiles
}: ImportFilesProps) => {
  const { t } = useTranslation();

  const [assistantDocs] = useState<StringKeys[]>(selectedDocs);
  const [currentResourceId, setCurrentResourceId] = useState<string | null>(
    null
  );

  const isRowClickDisabled = useCallback(
    (row) => {
      return (
        row?.status_code !== DOCUMENT_STATUS.PROCESSED &&
        row?.type_code !== RESOURCE_TYPE.FOLDER
      );
    },
    [currentResourceId]
  );

  const handleFileSelection = (files: StringKeys[]) => {
    const validFiles: StringKeys[] = files
      .filter((file) => typeof file === 'object' && file.id)
      .map((file) => ({
        rid: file.id,
        type_id: file.type_id,
        file: file
      }));

    if (validFiles.length > 0) {
      const allFiles = [...selectedDocs, ...validFiles];
      const uniqueFiles = allFiles.filter(
        (value, index, self) =>
          index === self.findIndex((obj) => obj.rid === value.rid)
      );
      // set only first 50 files into the setSelectedDocs
      setSelectedDocs(uniqueFiles.slice(0, MAX_FILES_ATTACHED));
    }
  };

  const handleInternalClose = () => {
    setSelectedDocs(assistantDocs);
    handleClose();
  };

  return (
    <DataLibraryExplorer
      open={open}
      handleClose={handleInternalClose}
      dialogTitle={t(T.importFromApp)}
      mainActionButtonText={t(T.addFiles)}
      currentResourceId={currentResourceId}
      setCurrentResourceId={setCurrentResourceId}
      disabledActionButtonMsg={t(T.selectItemsToImport)}
      sources={{}}
      ignoreExemptionOfWorkspace
      handleDialogAction={handleImportFiles}
      onSelect={handleFileSelection}
      showSelectionColumn
      disableActionButton={false}
      isRowClickDisabled={isRowClickDisabled}
      isFolderSelectionEnabled={false}
    />
  );
};

export default ImportFiles;
