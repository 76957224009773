import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, InputBase, useTheme } from '@mui/material';
import { AutoFixHighOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ONBOARDING_STEPS_TARGET } from 'src/components/UserOnboard/constants';
import PROMPT_LIBRARY from 'src/utils/promptLibrary';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { KEYBOARD_EVENT, splitLastWordFromString } from '../utils';
import { PromptOptionsBox, PromptOptionWrapper, PromptTitle } from '../styles';

type ChatInputProps = {
  textInput: string;
  setTextInput: any;
  inputRef: any;
  handleKeyPress: any;
  isEmpowerGPTChat: boolean;
};

type Option = {
  id: number;
  title: string;
  prompt: string;
};
const ChatInput = ({
  textInput,
  setTextInput,
  handleKeyPress,
  isEmpowerGPTChat,
  inputRef
}: ChatInputProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isNotAPromptOption = -1;
  const [focusedIndex, setFocusedIndex] = useState<number>(isNotAPromptOption);
  const [inputBaseHeight, setInputBaseHeight] = useState<number>(0);
  const [isPromptPopoverOpen, setIsPromptPopoverOpen] =
    useState<boolean>(false);
  const [promptOptions, setPromptOptions] = useState<Option[]>();
  const optionRefs = useRef<Array<HTMLDivElement | null>>([]);

  const filterPromptOptions = useCallback((value: string) => {
    const { lastWord } = splitLastWordFromString(value);
    if (!lastWord.startsWith('/')) {
      setIsPromptPopoverOpen(false);
      setFocusedIndex(isNotAPromptOption);
      return;
    }
    const searchQuery = lastWord.substring(1).toLowerCase();
    const filteredOptions = searchQuery
      ? PROMPT_LIBRARY.filter((option) =>
          t(option.title).toLowerCase().includes(searchQuery)
        )
      : PROMPT_LIBRARY;

    setPromptOptions(filteredOptions);
    setIsPromptPopoverOpen(filteredOptions.length > 0);
    setFocusedIndex(filteredOptions.length > 0 ? 0 : isNotAPromptOption);
  }, []);

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setTextInput(value);

      if (isEmpowerGPTChat) return;

      filterPromptOptions(value);
    },
    [isEmpowerGPTChat]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case KEYBOARD_EVENT.DOWN:
          if (isPromptPopoverOpen) {
            setFocusedIndex(
              (prevIndex) => (prevIndex + 1) % promptOptions.length
            );
          }
          break;
        case KEYBOARD_EVENT.UP:
          if (isPromptPopoverOpen) {
            setFocusedIndex(
              (prevIndex) =>
                (prevIndex - 1 + promptOptions.length) % promptOptions.length
            );
          }
          break;
        case KEYBOARD_EVENT.ENTER:
          if (focusedIndex !== isNotAPromptOption) {
            event.preventDefault();
            const promptText = t(promptOptions[focusedIndex].prompt);
            setTextInput(
              splitLastWordFromString(textInput).remainingString + promptText
            );
            setIsPromptPopoverOpen(false);
            setFocusedIndex(isNotAPromptOption);
          } else if (!isPromptPopoverOpen && !event.shiftKey) {
            event.preventDefault();
            handleKeyPress();
          }
          break;
        default:
          break;
      }
    },
    [
      promptOptions,
      isPromptPopoverOpen,
      focusedIndex,
      textInput,
      handleKeyPress
    ]
  );
  const setInputFocus = useCallback(() => {
    inputRef?.current?.focus();
  }, [inputRef]);
  const handleOptionClick = useCallback(
    (index: number) => {
      const promptText = t(promptOptions[index].prompt);
      setTextInput(
        splitLastWordFromString(textInput).remainingString + promptText
      );
      setIsPromptPopoverOpen(false);
      setFocusedIndex(isNotAPromptOption);
    },
    [promptOptions, textInput]
  );

  useEffect(() => {
    if (
      focusedIndex !== isNotAPromptOption &&
      optionRefs.current[focusedIndex]
    ) {
      optionRefs.current[focusedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [focusedIndex]);

  useEffect(() => {
    const element = document.getElementById(
      ONBOARDING_STEPS_TARGET.CHAT_INPUT_FIELD
    );
    setInputBaseHeight(element.offsetHeight);
  }, [textInput]);

  return (
    <Box position={'relative'}>
      {isPromptPopoverOpen && !isEmpowerGPTChat && (
        <PromptOptionsBox props={{ inputBaseHeight }}>
          {promptOptions.map((prompt, index) => {
            return (
              <PromptOptionWrapper
                props={{ index, focusedIndex }}
                container
                ref={(el) => (optionRefs.current[index] = el)}
                key={prompt.id}
                onClick={() => handleOptionClick(index)}
              >
                <AutoFixHighOutlined fontSize="small" />
                <PromptTitle>{t(prompt.title)}</PromptTitle>
              </PromptOptionWrapper>
            );
          })}
        </PromptOptionsBox>
      )}
      <InputBase
        id={ONBOARDING_STEPS_TARGET.CHAT_INPUT_FIELD}
        onClick={setInputFocus}
        fullWidth
        placeholder={
          isEmpowerGPTChat
            ? t(T.queryPlaceholder)
            : t(T.queryPlaceholderWorldKnowledge)
        }
        sx={{
          flex: 1,
          m: 1,
          mr: 0,
          paddingRight: '14px',
          fontSize: theme.typography.pxToRem(14),
          '& textarea': {
            color: theme.colors.primary.main
          }
        }}
        value={textInput}
        onKeyDown={handleKeyDown}
        onChange={handleChangeInput}
        maxRows={10}
        multiline
        autoFocus
        inputRef={inputRef}
      />
    </Box>
  );
};

export default ChatInput;
