import { useMemo, useState } from 'react';
import { IconButton, Zoom, Tooltip } from '@mui/material';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { DataApiInputParams } from 'src/types/api';
import { usePatchMessageMutation } from 'src/services/api';
import { getReactionApiParams, getReactionsKeyValue } from '../../utils';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { ALERT, REACTION } from 'src/types/enum';
import FeedbackDialog from './FeedbackDialog';
import useMutation from 'src/hooks/useMutation';
import { ErrorContext } from 'src/utils/errorMappings';
import { IResponseFeedback } from '../../types';
import { BottomAlignedBox } from './styles';
import { useTranslation } from 'react-i18next';
import { setAlert } from 'src/redux/slices/snackbar';

type ReactionType = {
  id: number;
  message?: any;
  visibility: boolean;
  isSharedChat?: boolean;
};

const Reaction = ({ message, id, visibility, isSharedChat }: ReactionType) => {
  const [isFeedbackOpen, setFeedbackOpen] = useState(false);
  const [reaction, setReaction] = useState<string>(
    message?.reaction_code || ''
  );
  const [animateCopyReaction, setCopyAnimation] = useState<boolean>(false);
  const [addReaction, { isLoading: isUpdating }] = useMutation({
    api: usePatchMessageMutation,
    errorContext: ErrorContext.CHAT
  });
  const { lookups } = useSelector((state: RootState) => state.data);
  const [reaction_type] = useState(getReactionsKeyValue(lookups));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formatMessage = useMemo(() => {
    try {
      return JSON.parse(message?.message);
    } catch {
      return {
        response: message?.message
      };
    }
  }, [message]);

  const onSubmit = async (reaction, details?: IResponseFeedback) => {
    const apiParams: DataApiInputParams = getReactionApiParams(
      id,
      reaction_type[reaction],
      details
    );
    await addReaction({
      params: apiParams,
      fallbackMsg: T.errorAddingReaction,
      successCallback: () => setReaction(reaction)
    });
  };

  const onDislike = () => {
    onSubmit(REACTION.THUMBS_DOWN);
    setFeedbackOpen(true);
  };

  const onLike = () => {
    onSubmit(REACTION.THUMBS_UP);
  };

  const onCopy = () => {
    navigator.clipboard
      .writeText(formatMessage?.response)
      .then(() => {
        setCopyAnimation(true);
        setTimeout(onCopyReactionTimeout, 2000);
      })
      .catch(() => {
        dispatch(
          setAlert({
            msg: t(T.errorCopyingResponse),
            type: ALERT.ERROR
          })
        );
      });
  };

  const onCopyReactionTimeout = () => setCopyAnimation(false);
  const onFeedbackAdded = (data: IResponseFeedback) => {
    onSubmit(REACTION.THUMBS_DOWN, data);
    closeFeedback();
  };

  const closeFeedback = () => {
    setFeedbackOpen(false);
  };

  return (
    <BottomAlignedBox
      sx={{
        visibility: visibility && formatMessage?.response ? 'visible' : 'hidden'
      }}
    >
      <Tooltip
        title={!animateCopyReaction ? t(T.copy) : t(T.copied)}
        placement="top"
      >
        <IconButton
          onClick={!animateCopyReaction ? onCopy : () => undefined}
          color={!animateCopyReaction ? 'default' : 'success'}
        >
          <Zoom
            in={!animateCopyReaction}
            style={{ display: !animateCopyReaction ? 'inherit' : 'none' }}
          >
            <ContentCopyIcon fontSize="small" color="disabled" />
          </Zoom>
          <Zoom
            in={animateCopyReaction}
            style={{
              display: animateCopyReaction ? 'inherit' : 'none'
            }}
          >
            <DoneAllRoundedIcon fontSize="small" color="success" />
          </Zoom>
        </IconButton>
      </Tooltip>
      {(!reaction || reaction === REACTION.THUMBS_UP) && (
        <IconButton
          onClick={onLike}
          value={REACTION.THUMBS_UP}
          color="success"
          disabled={isUpdating || !!reaction || isSharedChat}
        >
          {reaction ? (
            <ThumbUpIcon fontSize="small" color="success" />
          ) : (
            <ThumbUpOutlinedIcon
              fontSize="small"
              color="disabled"
              sx={{
                ...(isSharedChat && { display: 'none' })
              }}
            />
          )}
        </IconButton>
      )}

      {(!reaction || reaction === REACTION.THUMBS_DOWN) && (
        <IconButton
          disabled={isUpdating || !!reaction || isSharedChat}
          onClick={onDislike}
          value={REACTION.THUMBS_DOWN}
          color="error"
        >
          {reaction ? (
            <ThumbDownIcon fontSize="small" color="error" />
          ) : (
            <ThumbDownOutlinedIcon
              fontSize="small"
              color="disabled"
              sx={{
                ...(isSharedChat && { display: 'none' })
              }}
            />
          )}
        </IconButton>
      )}

      {isFeedbackOpen && (
        <FeedbackDialog
          isOpen={isFeedbackOpen}
          onSubmit={onFeedbackAdded}
          onClose={closeFeedback}
        />
      )}
    </BottomAlignedBox>
  );
};

export default Reaction;
