import { Box, Typography, useTheme } from '@mui/material';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { DocumentsContextProvider } from 'src/contexts/FileManagerContext';
import { SidebarContext } from 'src/contexts/SidebarContext';
import useLazyQuery from 'src/hooks/useLazyQuery';
import useMutation from 'src/hooks/useMutation';
import { drawerWidth } from 'src/layouts/AccentHeaderLayout/styles';
import {
  initialAssistantState,
  updateAssistantContext,
  updateInitiateAssistantState
} from 'src/redux/slices/assistant';
import { clearChatSnapshot, setSelectedChat } from 'src/redux/slices/chat';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import {
  useDeleteAssistantMutation,
  useLazyGetAllAssistantsQuery
} from 'src/services/api';
import { StringKeys } from 'src/types/base';
import { ASSISTANT_TABS, FAVOURITE_TYPE, RESOURCE_TYPE } from 'src/types/enum';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import ShareResource from '../Documents/ContentSharing';
import { resetAssistantContext, startChat } from '../Documents/utils/gridUtils';
import { getFavouredData } from '../Documents/utils/utils';
import { ActionButtons } from './Components/actionButtons';
import FilePermissionModal from './Components/FilePermissionModal';
import CreateAssistantForm from './CreateAssistantForm';
import GridView from './gridView';
import PageHeader from './PageHeader';
import { getFilters } from './utils/filter';
import { MainContentWrapper } from './utils/styles';
import { IAssistantTabProps } from './utils/types';
import {
  DEFAULT_ASSISTANT_FILTER,
  initialAssistantTabs,
  sortList
} from './utils/utils';

const Assistant: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { assistantContext, assistantObj } = useSelector(
    (state) => state.assistant
  );
  const { configuredModels, userFavourites, icons } = useSelector(
    (state) => state.data
  );
  const [rows, setRows] = useState<StringKeys>([]);
  const [selectedTab, setSelectedTab] = useState<string>(ASSISTANT_TABS.ALL);
  const [filtersValue, setFiltersValue] = useState<StringKeys>({});
  const gridRef = useRef<HTMLDivElement | null>(null);
  const { sidebarToggle } = useContext(SidebarContext);
  const [isCreateModalPopupOpen, setCreateModalPopupOpen] =
    useState<boolean>(false);
  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [isFileModalOpen, setFileModalOpen] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>(
    DEFAULT_ASSISTANT_FILTER
  );
  const [deletedRowId, setDeletedRowId] = useState<any>([]);
  const { selectedChat } = useSelector((state: RootState) => state.chat);

  const [currentAssistantPerms, setCurrentAssistantPerms] =
    useState<StringKeys>();
  const [currentAssistantDetails, setCurrentAssistantDetails] = useState<any>();

  const [sharedDocument, setSharedDocument] = useState<any>(null);

  const [isShareResourceDialogOpen, setIsShareResourceDialogOpen] =
    useState(false);

  const [assistantTabs, setAssistantTabs] =
    useState<IAssistantTabProps[]>(initialAssistantTabs);

  const [fetchAllAssistants, { isFetching, data: assistantsData }] =
    useLazyQuery({
      api: useLazyGetAllAssistantsQuery,
      errorContext: ErrorContext.ASSISTANT
    });

  const [deleteAssistant] = useMutation({
    api: useDeleteAssistantMutation,
    errorContext: ErrorContext.ASSISTANT
  });

  useEffect(() => {
    initializeAssistantTabs();
    getAllAssistants(true, selectedFilter);
    dispatch(updateInitiateAssistantState(initialAssistantState));
    dispatch(
      updateAssistantContext({
        ...assistantContext,
        isAssistant: true
      })
    );
    // clean up Assistants
    // return () => {
    //   resetAssistantContext(dispatch);
    // };
  }, []);

  useEffect(() => {
    if (assistantsData?.data && !isFetching) {
      setRows(
        getFavouredData(
          assistantsData?.data,
          userFavourites,
          FAVOURITE_TYPE?.ASSISTANT
        )
      );
    }
  }, [assistantsData, userFavourites, isFetching]);

  useEffect(() => {
    if (selectedTab) {
      switch (selectedTab) {
        case ASSISTANT_TABS.CREATED_BY_ME:
          getAllAssistants(false, selectedFilter);
          break;
        case ASSISTANT_TABS.ALL:
          getAllAssistants(true, selectedFilter);
          break;

        default:
          break;
      }
    }
  }, [selectedTab]);

  const getAllAssistants = (fetchAll: boolean, orderBy?: string) => {
    fetchAllAssistants({
      params: {
        params: {
          fetch_all: fetchAll,
          ...(orderBy && {
            order_by: orderBy
          })
        }
      }
    });
  };

  const initializeAssistantTabs = () => {
    setAssistantTabs((prev) =>
      prev.map((tab: IAssistantTabProps) => {
        switch (tab.value) {
          case ASSISTANT_TABS.ALL:
            return {
              ...tab,
              label: t(T.allAssistant)
            };
          case ASSISTANT_TABS.CREATED_BY_ME:
            return {
              ...tab,
              label: t(T.createdByMe)
            };
          default:
            return tab;
        }
      })
    );
  };

  const handleCreateModalOpen = () => {
    setCreateModalPopupOpen(true);
    setCurrentAssistantDetails(null);
  };

  const handleUpdateCreateModalOpen = (assistant: any) => {
    setCurrentAssistantDetails(assistant);
    setCreateModalPopupOpen(true);
  };

  const handleCreateModalClose = () => {
    setCreateModalPopupOpen(false);
    resetAssistantContext(dispatch);
    dispatch(clearChatSnapshot(0));
    dispatch(setSelectedChat(undefined));
    dispatch(updateInitiateAssistantState(initialAssistantState));
    dispatch(
      updateAssistantContext({
        ...assistantContext,
        isPreview: false,
        isCreatingAssistant: false
      })
    );
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleShareResourceClick = (document: Document) => {
    setSharedDocument(document);
    setIsShareResourceDialogOpen(true);
  };

  const dialogHeaderTitle = useMemo(() => {
    return (
      <Typography fontSize={'26px'} fontWeight={500}>
        {currentAssistantDetails ? t(T.updateAssistant) : t(T.createAssistant)}
      </Typography>
    );
  }, [currentAssistantDetails]);

  const filters = useMemo(() => {
    return getFilters({
      sortList: sortList
    });
  }, []);

  const onSearch = useCallback(
    (searchValue) => {
      const searchText = searchValue['s'];
      if (searchText) {
        const filteredRows = assistantsData?.data?.filter((row: any) =>
          row.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setRows(filteredRows);
      } else {
        getAllAssistants(true, searchValue?.code?.code || selectedFilter);
        if (searchValue?.code?.code) setSelectedFilter(searchValue?.code?.code);
      }
    },
    [rows, assistantsData?.data]
  );

  const onClickReset = (data: any) => {
    getAllAssistants(true);
  };

  const handleDelete = useCallback(() => {
    deleteAssistant({
      params: {
        params: { id: deletedRowId }
      },
      successMsg: t(T.assistantDeletedSuccessfully),
      successCallback(data) {
        setDeleteOpen(false);
        getAllAssistants(true, selectedFilter);
      }
    });
  }, [deletedRowId]);

  const handleResourceSharingDialogClose = () => {
    setIsShareResourceDialogOpen(false);
    setSharedDocument(null);
  };

  const handleStartChatClick = (assistant: any) => {
    if (
      (assistant?.settings?.web_search_enabled ||
        assistant?.has_permission ||
        assistant?.settings?.world_knowledge) &&
      assistant?.aiModel
    ) {
      dispatch(
        updateAssistantContext({
          ...assistantContext,
          isAssistant: true,
          isStartChat: true,
          isPreview: false
        })
      );
      dispatch(
        updateInitiateAssistantState({
          aiModel: configuredModels.filter(
            (model) => model.id === parseInt(assistant?.settings.model_id)
          )[0],
          worldKnowledge: assistant?.settings?.world_knowledge,
          isWebSearch: assistant?.settings?.web_search_enabled
        })
      );

      startChat({
        documentContext: {
          id: assistant?.id,
          name: assistant?.name,
          assistant_id: assistant?.id
        },
        dispatch,
        navigate,
        selectedChat,
        resourceType: RESOURCE_TYPE.ASSISTANT
      });
    } else {
      setCurrentAssistantPerms(assistant);
      setFileModalOpen(true);
    }
  };

  const handleCloseFileModal = useCallback(() => setFileModalOpen(false), []);
  const renderGrid = useMemo(() => {
    return (
      <MainContentWrapper>
        <DocumentsContextProvider>
          <GridView
            rows={rows}
            icons={icons}
            handleShareResourceClick={handleShareResourceClick}
            handleStartChatClick={handleStartChatClick}
            setDeletedRowId={setDeletedRowId}
            setDeleteOpen={setDeleteOpen}
            handleCreateModalOpen={handleUpdateCreateModalOpen}
            handleFetchAssistants={getAllAssistants}
          />
        </DocumentsContextProvider>
      </MainContentWrapper>
    );
  }, [rows, icons]);

  return (
    <>
      <Helmet>
        <title>{`${T.empowerGPT} - ${t(T.assistants)}`}</title>
      </Helmet>
      <Box
        sx={{
          width: { xs: '100%', lg: '100%' }
        }}
        height={'100%'}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          ref={gridRef}
          padding={2}
          sx={{
            height: '100%',
            backgroundColor: theme.colors.alpha.white[100],
            ...(sidebarToggle && { width: `calc(100vw - ${drawerWidth}px)` })
          }}
        >
          <PageHeader
            title={t(T.assistants)}
            showTabs={true}
            selectedTab={selectedTab}
            tabs={assistantTabs}
            setSelectedTab={setSelectedTab}
            actionButtons={ActionButtons({
              showCreateButton: true,
              handleCreateAssistant: handleCreateModalOpen
            })}
            filterProps={{
              elementsList: filters,
              onSearch: onSearch,
              onReset: onClickReset,
              forceReset: true,
              showFilters: true,
              disableFilters: false,
              filtersValue,
              setFiltersValue,
              isDropDown: true
            }}
          />
          {renderGrid}
        </Box>
      </Box>
      {isDeleteOpen && (
        <ConfirmationDialog
          message={t(T.collectionDeleteConfirmation)}
          onClose={handleDeleteClose}
          onConfirm={handleDelete}
          isOpen={isDeleteOpen}
        />
      )}
      {isCreateModalPopupOpen && (
        <CreateAssistantForm
          title={dialogHeaderTitle}
          isOpen={isCreateModalPopupOpen}
          onClose={handleCreateModalClose}
          handleFetchAssistants={getAllAssistants}
          assistantDetails={currentAssistantDetails}
          icons={icons}
          setSharedDocument={setSharedDocument}
          setIsShareResourceDialogOpen={setIsShareResourceDialogOpen}
          selectedFilter={selectedFilter}
        />
      )}
      {isShareResourceDialogOpen && sharedDocument && (
        <ShareResource
          isOpen={isShareResourceDialogOpen}
          onClose={handleResourceSharingDialogClose}
          resource={sharedDocument}
          resourceType={RESOURCE_TYPE.ASSISTANT}
        />
      )}
      {isFileModalOpen && (
        <FilePermissionModal
          isFileModalOpen={isFileModalOpen}
          handleCloseFileModal={handleCloseFileModal}
          rowData={currentAssistantPerms}
        />
      )}
    </>
  );
};

export default Assistant;
