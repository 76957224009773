import { FC, useState, createContext, useEffect, useRef } from 'react';
import { useSelector } from 'src/redux/store';
import { WebPubSubClient } from '@azure/web-pubsub-client';
import { getEncryptParams } from 'src/keycloak';
import { decryptResponse } from 'src/services/utils';
import { useParams } from 'react-router';
import logger from 'src/utils/logger';
import { IS_ENCRYPTION_ENABLED } from 'src/config';
import { PubsubEvents } from 'src/types/enum';

type AzureWebPubSubChatContextType = {
  connectionId: string;
  streamMessage: string;
  chatMeta?: any;
  chatStep?: any;
  error?: any;
  resetContext: () => void;
};

export const AzureWebPubSubChatContext =
  createContext<AzureWebPubSubChatContextType>(
    {} as AzureWebPubSubChatContextType
  );

export const AzureWebPubSubChatProvider: FC = ({ children }) => {
  const [connectionId, setConnectionId] = useState<string>();
  const { azureWebPubSubInfo } = useSelector((state) => state.auth);
  const [streamMessage, setStreamMessage] = useState<string>('');
  const [chatStep, setChatStep] = useState<string>('');
  const [chatMeta, setChatMeta] = useState();
  const [error, setError] = useState();
  const { id } = useParams();
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    const wpsClient = new WebPubSubClient(azureWebPubSubInfo.url);
    wpsClient.on('connected', (e) => {
      if (isMounted.current) {
        logger.log('connected chat wps connection', e.connectionId);
        setConnectionId(e.connectionId);
      }
    });
    wpsClient.on('disconnected', (e) => {
      logger.log('disconnected chat wps connection', e.connectionId);
      if (isMounted.current) {
        setConnectionId(undefined);
        resetContext();
      }
    });
    wpsClient.on('server-message', ({ message }: any) => {
      const decryptKey = getEncryptParams()?.k;
      let data = decryptResponse(message, decryptKey);
      if (!IS_ENCRYPTION_ENABLED) {
        data = JSON.parse(data);
      }
      switch (data?.event) {
        case PubsubEvents.STREAM:
          setStreamMessage((msg) => msg + data.response);
          break;
        case PubsubEvents.CHAT_META:
          setChatMeta(data);
          break;
        case PubsubEvents.CHAT_STEP:
          setChatStep(data?.response);
          break;
        case PubsubEvents.ERROR:
          setError(data);
          break;
        default:
          break;
      }
    });
    wpsClient.start();
    return () => {
      isMounted.current = false;
      wpsClient?.stop();
    };
  }, [id, azureWebPubSubInfo.url]);

  const resetContext = () => {
    if (isMounted.current) {
      setStreamMessage('');
      setChatMeta(undefined);
      setError(undefined);
      setChatStep(undefined);
    }
  };

  return (
    <AzureWebPubSubChatContext.Provider
      value={{
        connectionId,
        streamMessage,
        resetContext,
        chatMeta,
        chatStep,
        error
      }}
    >
      {children}
    </AzureWebPubSubChatContext.Provider>
  );
};
