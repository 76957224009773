import { AddOutlined } from '@mui/icons-material';
import { Box, Radio, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IS_WEB_SEARCH_ENABLED } from 'src/config';
import {
  AssistantType,
  updateInitiateAssistantState
} from 'src/redux/slices/assistant';
import { useDispatch, useSelector } from 'src/redux/store';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import {
  CustomAddFileBox,
  FileDisclaimerText,
  StyledFileListBox
} from '../utils/styles';
import FileListIcon from './FileListIcon';

const Capabilities = ({
  setShowFilesGrid,
  setNewAssistant,
  newAssistant,
  showFilesGrid,
  handleFileSelectionModalOpen,
  isFileSelectionModalOpen,
  selectedDocs,
  setSelectedDocs
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { assistantObj } = useSelector((state) => state.assistant);

  useEffect(() => {
    if (selectedDocs.length > 0) {
      setNewAssistant({
        ...newAssistant,
        isWebSearch: false,
        worldKnowledge: false,
        fileList: selectedDocs
      });

      dispatch(
        updateInitiateAssistantState({
          ...assistantObj,
          isWebSearch: false,
          worldKnowledge: false,
          fileList: selectedDocs
        })
      );
    }
  }, [selectedDocs]);

  const handleKnowledgeBtnChange = (_, val) => {
    setShowFilesGrid(val);
    const tempAssistantObj: AssistantType = {
      ...newAssistant,
      isWebSearch: false,
      worldKnowledge: false,
      fileList: selectedDocs
    };
    setNewAssistant(tempAssistantObj);
    dispatch(
      updateInitiateAssistantState({
        ...assistantObj,
        isWebSearch: false,
        worldKnowledge: false,
        fileList: selectedDocs
      })
    );
  };

  const handleWebSearchBtnChange = (_, val) => {
    const tempAssistantObj: AssistantType = {
      ...newAssistant,
      isWebSearch: val,
      worldKnowledge: false
    };
    setNewAssistant(tempAssistantObj);
    dispatch(
      updateInitiateAssistantState({
        ...assistantObj,
        isWebSearch: val,
        fileList: [],
        worldKnowledge: false
      })
    );
    setShowFilesGrid(false);
  };

  const handleWorldKnowledgeChange = (_, val) => {
    const tempAssistantObj: AssistantType = {
      ...newAssistant,
      isWebSearch: false,
      worldKnowledge: val
    };
    setNewAssistant(tempAssistantObj);
    dispatch(
      updateInitiateAssistantState({
        ...assistantObj,
        isWebSearch: false,
        worldKnowledge: val,
        fileList: []
      })
    );
    setShowFilesGrid(false);
  };

  return (
    <Box>
      <Typography mb="10px" variant="body1">
        {t(T.capabilities)}
      </Typography>
      <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
        <Box display={'flex'} flexDirection={'row'}>
          <Radio
            onChange={handleWorldKnowledgeChange}
            checked={!!newAssistant.worldKnowledge}
          />
          <Typography variant="body1" mt={'10px'}>
            {t(T.worldKnowledge)}
          </Typography>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Box display={'flex'} flexDirection={'row'}>
            <Radio
              checked={showFilesGrid}
              onChange={handleKnowledgeBtnChange}
            />
            <Typography variant="body1" mt={'10px'}>
              {t(T.knowledgeAddFiles)}
            </Typography>
          </Box>
          {showFilesGrid && (
            <Typography variant="body1" mt={'10px'}>
              {t(T.knowledgeAddFilesAdded, {
                totalFiles: selectedDocs.length
              })}
            </Typography>
          )}
        </Box>
        {showFilesGrid && (
          <Box>
            <FileDisclaimerText variant="body1">
              {t(T.addFileDisclaimer)}
            </FileDisclaimerText>
            <StyledFileListBox ml={'35px'}>
              <CustomAddFileBox onClick={handleFileSelectionModalOpen}>
                <AddOutlined />
                <Typography variant="body1">{t(T.addFiles)}</Typography>
              </CustomAddFileBox>
              {!isFileSelectionModalOpen &&
                selectedDocs.map((doc: any, index) => (
                  <Box key={index}>
                    <FileListIcon
                      file={doc?.file || doc}
                      selectedDocs={selectedDocs}
                      setSelectedDocs={setSelectedDocs}
                    />
                  </Box>
                ))}
            </StyledFileListBox>
          </Box>
        )}
        {IS_WEB_SEARCH_ENABLED && (
          <Box display={'flex'} flexDirection={'row'}>
            <Radio
              checked={!!newAssistant.isWebSearch}
              onChange={handleWebSearchBtnChange}
            />
            <Typography variant="body1" mt={'10px'}>
              {t(T.worldKnowledgeWithWebSearch)}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Capabilities;
