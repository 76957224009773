import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { updateInitiateAssistantState } from 'src/redux/slices/assistant';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useDispatch, useSelector } from 'src/redux/store';

const AiModels = ({
  newAssistant,
  setNewAssistant,
  configuredModels,
  errors
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { assistantObj } = useSelector((state) => state.assistant);

  const onChange = (_, value) => {
    setNewAssistant({ ...newAssistant, ['aiModel']: value });
    dispatch(
      updateInitiateAssistantState({
        ...assistantObj,
        aiModel: configuredModels?.filter(
          (model) => model.id === value?.id
        )?.[0]
      })
    );
  };
  return (
    <Box>
      <Typography mb="10px" variant="body1">
        {t(T.aiModels)}
      </Typography>
      <Autocomplete
        id="assistant models"
        size="medium"
        options={configuredModels}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option?.display_name
        }
        value={newAssistant.aiModel}
        onChange={onChange}
        disableClearable
        popupIcon={<KeyboardArrowDownIcon fontSize="medium" />}
        renderInput={(params) => {
          return (
            <Box>
              <TextField
                {...params}
                sx={{ cursor: 'pointer' }}
                placeholder={t(T.aiModelsPlaceholder)}
                error={!!errors.aiModal}
                helperText={errors.aiModal?.message || ''}
                InputProps={{ ...params.InputProps, readOnly: true }}
              />
            </Box>
          );
        }}
      />
      {!newAssistant.aiModel && (
        <Typography variant="body1" color="red">
          {t(T.aiModelErrorMessage)}
        </Typography>
      )}
    </Box>
  );
};

export default AiModels;
