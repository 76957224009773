import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  IconButton,
  Grid,
  styled
} from '@mui/material';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Delete,
  GroupsOutlined,
  InfoOutlined,
  PersonOutlineOutlined
} from '@mui/icons-material';
import Loader from 'src/components/Loader';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { ACCOUNT_TYPE, CONTENT_SHARED_WITH_TYPE } from 'src/types/enum';
import { ORGANIZATION_ADMINISTRATOR } from '../utils/utils';
import Label from 'src/components/Label';
import { useSelector } from 'src/redux/store';

type SharedWithUser = {
  rid: string | number;
  members: string | number;
  code: string;
  role: string;
  name: string;
  value: string;
  email: string;
  p_id: string;
  type_id: string | number;
};

type IProps = {
  sharedWithUsers: SharedWithUser[];
  isFetchingAccessRights: boolean;
  onRemoveAccessRight: (accessRight: any) => void;
  removedAccessRight: string[];
  searchQuery: string;
  hideRemoveAccessRights: boolean;
};

const SharedWithUsers: React.FC<IProps> = ({
  sharedWithUsers,
  isFetchingAccessRights,
  onRemoveAccessRight,
  removedAccessRight,
  searchQuery,
  hideRemoveAccessRights = false
}: IProps) => {
  const [accessRights, setAccessRights] = useState<any>([]);
  const { t } = useTranslation();
  const { user: loggedInUser } = useSelector((state) => state.auth);

  useEffect(() => {
    setAccessRights(
      sharedWithUsers?.filter((user) => !removedAccessRight.includes(user.p_id))
    );
  }, [sharedWithUsers, removedAccessRight]);

  const dialogContent = useMemo(() => {
    if (isFetchingAccessRights) {
      return (
        <Box
          sx={(theme) => ({
            padding: 5,
            border: `1px solid ${theme.colors.primaryAlt.main}`,
            borderTop: 'none',
            height: 'calc(100% - 88px)',
            paddingTop: '50px'
          })}
        >
          <Loader />
        </Box>
      );
    }
    if (!accessRights?.length) {
      return (
        <Box
          sx={(theme) => ({
            textAlign: 'center',
            margin: 'auto',
            border: `1px solid ${theme.colors.primaryAlt.main}`,
            borderTop: 'none',
            height: 'calc(100% - 88px)',
            paddingTop: '60px'
          })}
        >
          <Typography variant={'h6'}>
            {searchQuery
              ? t(T.noMatchingUsersOrUserGroupsFound)
              : t(T.noUserOrUserGroupHasAccessToResource)}
          </Typography>
        </Box>
      );
    }
    return (
      <List
        sx={(theme) => ({
          padding: 0,
          overflow: 'auto',
          height: 'calc(100% - 88px)',
          border: `1px solid ${theme.colors.primaryAlt.main}`,
          borderTop: 'none'
        })}
      >
        {accessRights?.map((user) => (
          <Fragment key={user.p_id}>
            <ListItem
              key={user.p_id}
              sx={(theme) => ({
                padding: `${theme.spacing(1)} ${theme.spacing(2)}`
              })}
            >
              <ListItemIcon sx={{ minWidth: 'unset', paddingRight: '16px' }}>
                {user.code === CONTENT_SHARED_WITH_TYPE.USER_GROUP ? (
                  <GroupsOutlined />
                ) : (
                  <PersonOutlineOutlined />
                )}
              </ListItemIcon>
              <ListItemText
                primary={user.name}
                secondary={
                  <span style={{ fontSize: '13px' }}>
                    {user.code === CONTENT_SHARED_WITH_TYPE.USER_GROUP
                      ? `Members: ${user.members}`
                      : user.email}
                  </span>
                }
              />
              <Label showEllipses>{user.role}</Label>
              {loggedInUser?.accountType !== ACCOUNT_TYPE.REGULAR_USER &&
                !hideRemoveAccessRights && (
                  <ListItemIcon
                    sx={{
                      minWidth: 'unset',
                      paddingLeft: '24px'
                    }}
                  >
                    <IconButton
                      onClick={() => onRemoveAccessRight(user)}
                      color="error"
                      size="small"
                      sx={{ minWidth: 'fit-content' }}
                      disabled={
                        user.code === CONTENT_SHARED_WITH_TYPE.USER_GROUP &&
                        user.name === ORGANIZATION_ADMINISTRATOR
                      }
                    >
                      <Delete />
                    </IconButton>
                  </ListItemIcon>
                )}
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
    );
  }, [accessRights, isFetchingAccessRights, searchQuery]);

  return (
    <>
      {dialogContent}
      <Grid
        container
        direction={'row'}
        sx={{ alignItems: 'center', paddingTop: '4px' }}
      >
        <InfoOutlined fontSize={'small'} />
        <Typography
          color={'#757575'}
          sx={{ fontSize: '13px', paddingLeft: '4px' }}
        >
          {t(T.authorizedToRemoveLowTierAccountTypesOnly)}
        </Typography>
      </Grid>
    </>
  );
};

export default SharedWithUsers;
