import { Card, CardContent, Typography } from '@mui/material';
import { useCallback } from 'react';
import { RootState, useSelector } from 'src/redux/store';

type Props = {
  message: string;
  index?: number;
  handleQuestionCardClick?: (index: number) => void;
};

const QuestionCard = ({ message, index, handleQuestionCardClick }: Props) => {
  const { assistantContext } = useSelector(
    (state: RootState) => state.assistant
  );

  const handleCardClick = useCallback(() => {
    if (
      assistantContext?.isAssistant &&
      (index != null || index !== undefined) &&
      handleQuestionCardClick
    ) {
      handleQuestionCardClick(index);
    }
  }, [index, handleQuestionCardClick, assistantContext?.isAssistant]);

  return (
    <Card
      sx={{
        borderRadius: '10px',
        backgroundColor: '#F8F8F8',
        minHeight: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        maxWidth: '200px',
        minWidth: '200px',
        cursor: assistantContext?.isAssistant ? 'pointer' : 'default'
      }}
      onClick={handleCardClick}
    >
      <CardContent
        sx={{
          paddingY: '0',
          paddingBottom: '0 !important',
          textAlign: 'center'
        }}
      >
        <Typography>{typeof message === 'string' ? message : ''}</Typography>
      </CardContent>
    </Card>
  );
};

export default QuestionCard;
